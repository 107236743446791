import { Flex, Image, Select, Stack, Text } from '@chakra-ui/react';
import DetailsActive from 'assets/images/leads/details-active.svg';
import DetailsDefault from 'assets/images/leads/details-default.svg';
import ProspectActive from 'assets/images/leads/prospect-active.svg';
import ProspectDefault from 'assets/images/leads/prospect-default.svg';
import UserActive from 'assets/images/leads/user-active.svg';
import UserDefault from 'assets/images/leads/user-default.svg';

import Highlight from 'assets/images/inboxes/MdHighlight.svg';

import { CheckboxLayout } from 'components/app/Global/Checkbox';
import { SearchBar } from 'components/app/Global/SearchBar';

import { MY_LEADS, PROSPECTS, UNCLAIMED } from './constant';

import { useSideBarServices } from './useSideBarServices';

const PROSPECT_SUBTABS = [
  {
    id: 0,
    label: 'New Prospects',
    index: 'new-prospects',
    countId: 'prospect_status_count',
  },
  {
    id: 1,
    label: 'Prospects with Existing Clients',
    index: 'existing-client-prospects',
    countId: 'prospect_repeat_status_count',
  },
];
const LeadSideBar = () => {
  const {
    counts,
    tabValue,
    handleTab,
    searchLeads,
    prospectDateSort,
    setSearchLeads,
    prospectSubTab,
    setProspectSubTab,
    handleSearchLeads,
    setStatusMyLeadsPayload,
    setStatusUnclaimedPayload,
    setNewProspectsPayload,
    handleDateFilter,
  } = useSideBarServices();

  const SideBarItem = [
    {
      id: 0,
      label: 'My Leads',
      image: UserDefault,
      imageActive: UserActive,
      value: 'my-leads',
    },
    {
      id: 1,
      label: 'Unclaimed Leads',
      image: DetailsDefault,
      imageActive: DetailsActive,
      value: 'unclaimed',
    },
    {
      id: 2,
      label: 'Prospects',
      image: ProspectDefault,
      imageActive: ProspectActive,
      value: 'prospects',
    },
  ];

  return (
    <Stack
      spacing="9px"
      sx={{
        borderRadius: '10px',
        width: '300px',
        bgColor: '#F9F9FB',
        fontFamily: 'NunitoSans Regular',
      }}
    >
      <Stack
        spacing="16px"
        sx={{
          bgColor: '#fff',
          borderRadius: '10px',
        }}
      >
        <Stack spacing="10px" sx={{ position: 'relative', p: '16px' }}>
          {SideBarItem.map((val) => {
            const isActive = tabValue === val.value;
            return (
              <Flex
                gap="10px"
                sx={{
                  alignItems: 'center',
                  p: '4px 8px',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  background: isActive && '#6930CA',
                  color: isActive ? '#FFFFFF' : '#2B2D42',
                }}
                onClick={() => {
                  handleTab(val.value);
                }}
              >
                {isActive && (
                  <Image
                    src={Highlight}
                    height="35px"
                    width="4px"
                    sx={{ position: 'absolute', left: 0 }}
                  />
                )}
                <Image
                  src={isActive ? val.imageActive : val.image}
                  height="24px"
                  width="24px"
                />
                <Flex alignItems="center" columnGap="8px">
                  <Text sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {val.label}
                  </Text>
                  <Flex
                    bgColor={isActive ? '#ffff' : '#6930CA14'}
                    color="#6930CA"
                    borderRadius={'2px'}
                    minW={'23px'}
                    h={'18px'}
                    justifyContent="center"
                    alignItems="center"
                    p={'4px'}
                  >
                    <Text fontSize={'12px'} fontWeight={600}>
                      {counts[`${val.value.replaceAll('-', '')}`]}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
        </Stack>
      </Stack>

      {tabValue === 'prospects' ? (
        <Stack
          sx={{
            bgColor: '#6930CA0D',
            borderRadius: '10px',
            padding: '20px 16px',
          }}
        >
          <Text
            color="gray.600"
            fontSize="14px"
            fontWeight="600"
            lineHeight="19.1px"
          >
            Prospects
          </Text>
          {PROSPECT_SUBTABS.map((tab) => (
            <Flex
              alignItems="center"
              columnGap="8px"
              sx={{
                p: '4px 8px',
                cursor: 'pointer',
                borderRadius: '4px',
                background: prospectSubTab === tab.index && '#6930CA',
                color: prospectSubTab === tab.index ? '#FFFFFF' : '#2B2D42',
              }}
              onClick={() => setProspectSubTab(tab.index)}
            >
              <Text sx={{ fontSize: '14px', fontWeight: 600 }}>
                {tab.label}
              </Text>
              <Flex
                bgColor={prospectSubTab === tab.index ? '#ffff' : '#6930CA14'}
                color="#6930CA"
                borderRadius={'2px'}
                minW={'23px'}
                h={'18px'}
                justifyContent="center"
                alignItems="center"
                p={'4px'}
              >
                <Text fontSize={'12px'} fontWeight={600}>
                  {counts[`${tab.countId}`].All || 0}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Stack>
      ) : null}

      <Stack
        direction={'column'}
        gap="16px"
        sx={{
          bgColor: '#fff',
          borderRadius: '10px',
          p: '16px',
        }}
        height="100%"
      >
        <Stack direction="column" gap="16px">
          <Flex
            sx={{
              borderBottom: '1px solid #E7EDF4',
              pb: '8px',
              color: '#718096',
              fontSize: '16px',
              fontWeight: '600',
              // lineHeight: '21.82px',
            }}
          >
            Filters
          </Flex>

          <SearchBar
            search={searchLeads}
            setSearch={setSearchLeads}
            placeholder={
              tabValue === 'prospects'
                ? 'Search prospects...'
                : 'Search leads...'
            }
            handleOnChange={handleSearchLeads}
          />
        </Stack>

        <Stack spacing="8px">
          {tabValue === 'my-leads' && (
            <>
              <Text
                color="#718096"
                fontSize="14px"
                fontWeight="600"
                lineHeight="19.1px"
              >
                Status
              </Text>

              <CheckboxLayout
                data={MY_LEADS}
                setStatusPayload={setStatusMyLeadsPayload}
              />
            </>
          )}

          {tabValue === 'unclaimed' && (
            <>
              <Text
                color="#718096"
                fontSize="14px"
                fontWeight="600"
                lineHeight="19.1px"
              >
                Status
              </Text>
              <CheckboxLayout
                data={UNCLAIMED}
                setStatusPayload={setStatusUnclaimedPayload}
              />
            </>
          )}

          {tabValue === 'prospects' && (
            <>
              <Text
                color="#718096"
                fontSize="14px"
                fontWeight="600"
                lineHeight="19.1px"
              >
                Prospect Status
              </Text>
              <CheckboxLayout
                data={PROSPECTS}
                setStatusPayload={setNewProspectsPayload}
                counts={
                  prospectSubTab === PROSPECT_SUBTABS[0].index
                    ? counts.prospect_status_count
                    : counts.prospect_repeat_status_count
                }
              />
            </>
          )}
        </Stack>

        {tabValue === 'prospects' && (
          <Stack spacing="8px">
            <Text
              color="#718096"
              fontSize="14px"
              fontWeight="600"
              lineHeight="19.1px"
            >
              Created Date
            </Text>

            <Select
              placeholder="Select"
              _focus={{ borderColor: '#6930CA', boxShadow: 'none' }}
              _active={{ borderColor: '#6930CA' }}
              _hover={{ borderColor: '#6930CA' }}
              colorScheme="purple"
              value={prospectDateSort}
              onChange={(e) =>
                handleDateFilter(
                  e.target.value === 'null' ? null : e.target.value
                )
              }
            >
              <option value="null">All</option>
              <option value="Last Week">Last Week</option>
              <option value="Last Month">Last Month</option>
              <option value="Last 3 Months">Last 3 Months</option>
              <option value="Last 6 Months">Last 6 Months</option>
            </Select>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
export default LeadSideBar;
