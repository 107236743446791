import { debounce } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useListLeadsMutation,
  listLeads,
  useLeadCountsMutation,
  resetLead,
} from 'store/leads.slice';
import { MY_LEADS, PROSPECTS, UNCLAIMED } from './constant';

export const useSideBarServices = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState('my-leads');
  const [searchLeads, setSearchLeads] = useState('');
  const [prospectSubTab, setProspectSubTab] = useState('new-prospects');
  const [prospectDateSort, setProspectDateSort] = useState(null);

  const { leadPgBtn, counts } = useSelector((state: any) => state.leads);

  const [reqLeads] = useListLeadsMutation();
  const [reqLeadCounts] = useLeadCountsMutation();

  const handleSearchLeads = debounce((e: any) => {
    let query = e.target.value;

    if (searchLeads === '') {
      query = '';
    }

    let params = {
      ...leadPgBtn,
      query: query,
    };

    dispatch(listLeads({ leadPgBtn: params }));

    if (query.length >= 3 || searchLeads.length >= 3) {
      reqLeads({ data: params });
    }
  }, 300);

  const fetchLeads = useCallback(
    (tab: string, query?: string, resetSortOrder?: boolean) => {
      const myLeadStatus = MY_LEADS[0].items
        .filter((stat) => stat.status)
        .map((item) => item.id);
      const unclaimedStatus = UNCLAIMED[0].items
        .filter((stat) => stat.status)
        .map((item) => item.id);
      const prospectStatus = PROSPECTS[0].items
        .filter((stat) => stat.status)
        .map((item) => item.id);
      let params = {
        ...leadPgBtn,
        unclaimed: tab === 'unclaimed' ? true : false,
        is_prospect: tab.includes('prospects') ? 1 : 0,
        date_filter: tab.includes('prospects') ? prospectDateSort : null,
        is_repeat_prospect: tab.includes('prospects')
          ? tab === 'existing-client-prospects'
            ? 1
            : 0
          : null,
        status:
          tab === 'unclaimed'
            ? unclaimedStatus
            : tab.includes('prospects')
            ? prospectStatus
            : myLeadStatus,
        query: query || leadPgBtn.query,
      };
      if (resetSortOrder) {
        params['orderBy'] = {
          column_name: 'created_at',
          sort_order: 'DESC',
        };
      }

      dispatch(listLeads({ leadPgBtn: params }));

      reqLeads({ data: params });
    },
    [prospectDateSort, leadPgBtn, dispatch, reqLeads, prospectSubTab]
  );
  const handleTab = (e: any) => {
    setTabValue(e);
    setSearchLeads('');
    if (e === 'prospects') {
      setProspectSubTab('new-prospects');
    }
    fetchLeads(e, undefined, true);
  };
  function handleProspectSubTab(tab: string) {
    setProspectSubTab(tab);
    setSearchLeads('');
    fetchLeads(tab, undefined, true);
  }

  function handleDateFilter(date_filter: string) {
    setProspectDateSort(date_filter);
    let params = {
      ...leadPgBtn,
      date_filter,
    };
    dispatch(listLeads({ leadPgBtn: params }));

    reqLeads({ data: params });
  }

  const handleFilterPayload = useCallback(() => {
    fetchLeads(tabValue === 'prospects' ? prospectSubTab : tabValue);
  }, [tabValue, prospectSubTab]);

  useEffect(() => {
    reqLeadCounts({});

    return () => {
      dispatch(resetLead());
    };
  }, []);

  return {
    counts,
    tabValue,
    handleTab,
    searchLeads,
    prospectSubTab,
    prospectDateSort,
    setSearchLeads,
    setProspectSubTab: handleProspectSubTab,
    handleSearchLeads,
    setStatusMyLeadsPayload: handleFilterPayload,
    setStatusUnclaimedPayload: handleFilterPayload,
    setNewProspectsPayload: handleFilterPayload,
    handleDateFilter,
  };
};
