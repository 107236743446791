import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import * as Yup from 'yup';

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Text,
  Icon,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { cleanUpload } from 'store/uploads.slice';
import AtsDropZone from '../../Dropzone';
import ScrollToFieldError from 'components/app/ScrollError';
import { useParams } from 'react-router';
import moment from 'moment';
import { BsPercent } from 'react-icons/bs';

import {
  useListClientAgreementMutation,
  useUpdateClientAgreementMutation,
  useGetClientAgreementMutation,
} from 'store/clientagreement.slice';
import AtsSelect from 'components/app/AtsSelect';
import { initial } from 'lodash';
import { LeadAgreementAttachments } from 'modules/Leads/TabList/TabPanel/Agreements/Attachment';
import delay from 'utils/delay';
import { useViewClientMutation } from 'store/client.slice';

interface ClientAgrrmentModalProps {
  isOpen: any;
  onClose: any;
}

export default function ClientEditgreementModal({
  isOpen,
  onClose,
}: ClientAgrrmentModalProps) {
  const dispatch = useDispatch();

  const { uploaded, uploading } = useSelector((state: any) => state.uploads);
  const { clientData } = useSelector((state: any) => state.clients);
  const { clientAgreementPgBtn, clientAgreementData, clientAttachments } =
    useSelector((state: any) => state.clientAgreements);

  const params = useParams();
  const clientsId =
    params.clientsId || clientData?.id || clientAgreementData?.client_id;
  const [reqget] = useGetClientAgreementMutation();

  const [requpdate, resupdate] = useUpdateClientAgreementMutation();
  const [reqClientData] = useViewClientMutation();
  const [reqAgreement] = useListClientAgreementMutation();
  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchAgreementsList = async (param: object, id: number) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    await delay(200);
    await reqAgreement({
      data: param,
      id,
      signal: abortControllerRef.current.signal,
    });
  };
  const [hasNoExpiry, setHasNoExpiry] = useState(
    clientAgreementData?.no_expiry
  );
  const guaranteeTypeOptions = [
    {
      value: 'Replacement',
      label: 'Replacement',
    },
    {
      value: 'Pro-rated Refund',
      label: 'Pro-rated Refund',
    },
    {
      value: 'Refund',
      label: 'Refund',
    },
  ];
  const initialValues = {
    title: clientAgreementData.title,
    content: clientAgreementData.content,
    date_signed: moment(clientAgreementData.date_signed).format('YYYY-MM-DD'),
    valid_until: clientAgreementData.valid_until
      ? moment(clientAgreementData.valid_until).format('YYYY-MM-DD')
      : '',
    no_expiry: clientAgreementData.no_expiry,
    fee_pct: Number(clientAgreementData.fee_pct) || '',
    payable: clientAgreementData.payable || '',
    guarantee: clientAgreementData.guarantee || '',
    guarantee_type: clientAgreementData.guarantee_type || '',
  };
  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().required('Agreement name is required.'),
      content: Yup.string(),
      date_signed: Yup.string().required('Date signed is required.'),
      valid_until:
        // (!hasNoExpiry)
        //   ? Yup.string().required('Valid until is required.')
        //   : Yup.string(),
        Yup.string().when('no_expiry', {
          is: (no_expiry: boolean) => {
            return no_expiry === true;
          },
          then: Yup.string(),
          otherwise: Yup.string().required('Valid until is required.'),
        }),
      no_expiry: Yup.boolean(),
      fee_pct: Yup.number().required('Fee Percentage is required'),
      payable: Yup.string().required('Payable is required'),
      guarantee: Yup.string().required('Guarantee is required'),
      guarantee_type: Yup.string().required('Guarantee Type is required'),
    })
  );

  const handleNoExpiry = (setFieldValue: any) => {
    setHasNoExpiry((prev: boolean) => !prev);
  };

  const onSubmit = async (data: any) => {
    data['attachments'] = uploaded;
    if (hasNoExpiry) {
      data['valid_until'] = null;
    }
    // data['no_expiry'] = hasNoExpiry;
    data['fee_pct'] = String(data['fee_pct']);
    data['payable'] = Number(data['payable']);
    data['guarantee'] = Number(data['guarantee']);
    await requpdate({ data, id: clientAgreementData.id });
  };

  useEffect(() => {
    if (resupdate.isSuccess) {
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));

      reqget({ id: clientAgreementData.id });
      reqClientData({ id: clientData.id });
      fetchAgreementsList(clientAgreementPgBtn, Number(clientsId));
      onClose();
    }
  }, [resupdate.isSuccess]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Edit Agreement
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            {clientAgreementData && (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  handleChange,
                  setFieldValue,
                  handleSubmit,
                  errors,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <ScrollToFieldError />
                    <Box p="32px">
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(!!errors.title && touched.title)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Agreement Name
                          </FormLabel>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            placeholder="Agreement Name"
                            variant="outline"
                            value={values.title}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.title)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <Box mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.content && touched.content
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Description
                          </FormLabel>
                          <Input
                            id="content"
                            name="content"
                            type="textarea"
                            placeholder="description"
                            variant="outline"
                            value={values.content}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.content)}
                          </FormErrorMessage>
                        </FormControl>
                      </Box>
                      <Box mb="34px">
                        <LeadAgreementAttachments
                          attachments={clientAttachments}
                        />
                      </Box>
                      <Box mb="34px">
                        <FormControl>
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Attachments
                          </FormLabel>
                        </FormControl>
                      </Box>
                      <AtsDropZone
                        multipleFile={false}
                        uploadedEnd={(e: any) => {}}
                        deletedFile={(key: string) => {}}
                      />
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.date_signed && touched.date_signed
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Signed On
                          </FormLabel>
                          <Input
                            id="date_signed"
                            name="date_signed"
                            placeholder="Select Date"
                            size="md"
                            type="date"
                            value={values.date_signed}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.date_signed)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            !hasNoExpiry &&
                            Boolean(!!errors.valid_until && touched.valid_until)
                          }
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                            width="100%"
                          >
                            <Flex justifyContent="space-between">
                              <Box>
                                Valid Until{' '}
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </Box>
                              <Box>
                                <Checkbox
                                  colorScheme="purple"
                                  color="default.secondarytext"
                                  id="no_expiry"
                                  name="no_expiry"
                                  mx={1}
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      'no_expiry',
                                      e.target.checked
                                    );
                                    handleNoExpiry(setFieldValue);
                                  }}
                                  // defaultChecked={hasNoExpiry}
                                  isChecked={values.no_expiry}
                                >
                                  No expiry
                                </Checkbox>
                              </Box>
                            </Flex>
                          </FormLabel>
                          <Input
                            id="valid_until"
                            name="valid_until"
                            placeholder="Select Date"
                            size="md"
                            type="date"
                            value={values.valid_until}
                            disabled={hasNoExpiry}
                            onChange={handleChange}
                          />

                          {!hasNoExpiry && (
                            <FormErrorMessage>
                              {String(errors.valid_until)}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Flex>

                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.fee_pct && touched.fee_pct
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Fee Percentage{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <InputGroup>
                            <InputRightElement
                              pointerEvents="none"
                              children={
                                <Icon as={BsPercent} color="gray.500" />
                              }
                            />
                            <Input
                              id="fee_pct"
                              name="fee_pct"
                              type="number"
                              placeholder="0.00"
                              variant="outline"
                              textAlign="left"
                              value={values.fee_pct}
                              onChange={handleChange}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {String(errors.fee_pct)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.payable && touched.payable
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Payable{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <InputGroup>
                            <InputRightElement
                              pointerEvents="none"
                              pr="20px"
                              children={<Text color="gray.500">days</Text>}
                            />
                            <Input
                              id="payable"
                              name="payable"
                              type="text"
                              placeholder="0"
                              variant="outline"
                              textAlign="left"
                              value={values.payable}
                              onChange={(e: any) => {
                                const { value } = e.target;
                                const sanitizedValue = value.replace(
                                  /[^0-9]/g,
                                  ''
                                );
                                setFieldValue('payable', sanitizedValue);
                              }}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {String(errors.payable)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.guarantee && touched.guarantee
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Guarantee{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <InputGroup>
                            <InputRightElement
                              pointerEvents="none"
                              pr="20px"
                              children={<Text color="gray.500">days</Text>}
                            />
                            <Input
                              id="guarantee"
                              name="guarantee"
                              type="number"
                              placeholder="0"
                              variant="outline"
                              textAlign="left"
                              value={values.guarantee}
                              onChange={(e: any) => {
                                const { value } = e.target;
                                const sanitizedValue = value.replace(
                                  /[^0-9]/g,
                                  ''
                                );
                                setFieldValue('guarantee', sanitizedValue);
                              }}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {String(errors.guarantee)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.guarantee_type && touched.guarantee_type
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Guarantee Type{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <AtsSelect
                            variant="outline"
                            defaultValue={
                              values.guarantee_type !== ''
                                ? guaranteeTypeOptions.filter((type: any) => {
                                    return type.label === values.guarantee_type;
                                  })
                                : ''
                            }
                            onChange={(e: any) => {
                              setFieldValue(`guarantee_type`, e.label);
                            }}
                            options={guaranteeTypeOptions}
                            isSearchable={false}
                            styles={{
                              control: (styles: any) => ({
                                ...styles,
                                fontSize: '14px',
                                border: '1px solid #E7E9ED',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                boxShadow: 'none',
                                '&:hover': {},
                              }),
                              dropdownIndicator: (styles: any) => ({
                                ...styles,
                                color: '#676767',
                              }),
                              indicatorSeparator: () => ({
                                display: 'none',
                              }),
                              placeholder: (styles: any) => ({
                                ...styles,
                                fontSize: '14px',
                                color: '#9B9B9B',
                              }),
                              multiValue: (styles: any) => ({
                                ...styles,
                                background: '#F7FAFC',
                                border: '1px solid #E2E8F0',
                                borderRadius: '6px',
                                '&& div:first-of-type': {
                                  color: '#6930CA',
                                  fontWeight: 600,
                                },
                              }),
                            }}
                          />
                          <FormErrorMessage>
                            {String(errors.guarantee_type)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                    </Box>

                    <ModalFooter
                      position="sticky"
                      bottom="0"
                      background="default.white.100"
                      boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                    >
                      <Button
                        variant="solid"
                        type="submit"
                        disabled={uploading}
                      >
                        Update
                      </Button>
                    </ModalFooter>
                  </form>
                )}
              </Formik>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
