// React and Redux hooks
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Router and Navigation
import { useNavigate, useParams } from 'react-router';

// Form validation
import * as Yup from 'yup';

// Chakra UI components
import { useDisclosure, useToast } from '@chakra-ui/react';

// Lodash utility library
import { debounce } from 'lodash';

// Store slices - Jobs related mutations and actions
import {
  getJob,
  useCreateJobsMutation,
  useCreateQuickMutation,
  useGetJobsMutation,
  useListClientJobsMutation,
  useListJobsMutation,
  useUpdateJobsMutation,
  useUpdateQuickMutation,
} from 'store/jobs.slice';

// Store slices - Client related mutations and actions
import {
  listClients,
  setClientId,
  useClientLocationMutation,
  useAllListClientsMutation,
} from 'store/client.slice';

// Store slices - Constant related mutations
import {
  useGetCompensationsMutation,
  useGetCountriesMutation,
  useGetEducationsMutation,
  useGetEmpTypeMutation,
  useGetExperienceLevelMutation,
  useGetJobSpecsMutation,
  useGetWorkTypesMutation,
} from 'store/constant.slice';

// Store slices - User related mutations
import { useUserListMutation } from 'store/user.slice';

// Store slices - Geo related mutations
import { useSearchGeoTypesMutation } from 'store/geo.slice';

// Store slices - Leads related actions
import { getLead } from 'store/leads.slice';

// Store slices - Skills related mutations
import { useSkillListMutation } from 'store/skills.slice';

// Utilities
import { authStorage } from 'utils/localStorage';
import replacePlaceholders from 'utils/textPlaceholders';

// Constants
import { CAREER_PAGE_URL } from 'constants/values';
import jobAdDescTemplate from 'constants/jobAdDescTemplate';

interface JobPairingIntParam {
  client_job_title: string;
  candidate_first_name: string;
  job_url: string;
  job_name_url: string;
  job_posting_title: string;
}

interface useJobModalServicesProps {
  jobId: any;
  duplicate: any;
  isOpen: any;
  companyDef: any;
  contactOptionValue: any;
  onClose: any;
  isDrawer: any;
  contactDef: any;
}

export const useJobModalServices = ({
  jobId,
  duplicate,
  isOpen,
  companyDef,
  contactOptionValue,
  onClose,
  isDrawer,
  contactDef,
}: useJobModalServicesProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const toast = useToast();

  const { jobData, jobPgBtn, defaultPitch } = useSelector(
    (state: any) => state.jobs
  );

  const { userList } = useSelector((state: any) => state.user);

  const [reqUsers, resUsers] = useUserListMutation();
  const [recOptions, setRecruiters] = useState([]);
  const [reqGetJob, resGetJob] = useGetJobsMutation();

  const [jobPairTemplate, setJobPairTemplate] = useState(
    {} as JobPairingIntParam
  );

  const {
    allClient,
    client_location,
    selected_client,
    selected_client_location,
    allClientPageBtn,
  } = useSelector((state: any) => state.clients);

  const { clients } = useSelector((state: any) => state);

  const { selected_contact } = useSelector((state: any) => state.leads);
  const { countries, job_specs } = useSelector((state: any) => state.constants);

  const hasJobFormData: string | null = localStorage.getItem('jobFormData');
  const initJobFormData =
    hasJobFormData == null ? {} : JSON.parse(hasJobFormData);

  const [jobFormData, setJobFormData] = useState<any>(initJobFormData);
  // const [jobFormData, setJobFormData] = useState<any>({});

  let modalHeader =
    jobId || jobFormData?.job_id ? 'Edit Job' : 'Create New Job';
  if (duplicate) modalHeader = 'Duplicate Job';
  const isEditModal = modalHeader === 'Edit Job';

  const [reqJobs] = useListJobsMutation();
  const [reqClientJobs] = useListClientJobsMutation();
  const [reqCreateJob, resCreateJob] = useCreateJobsMutation();
  const [reqUpdateJob, resUpdateJob] = useUpdateJobsMutation();
  const [reqCreateQuick, resCreateQuick] = useCreateQuickMutation();
  const [reqUpdateQuick, resUpdateQuick] = useUpdateQuickMutation();
  const [reqcompany, resCompany] = useAllListClientsMutation();
  const [CompanyOption, setCompanyOption] = useState([]);
  const [contactOption, setContactOption] = useState(contactOptionValue);
  const [locationOption, setLocationOption] = useState([]);
  const [aMOption, setAmOption] = useState([]);
  const [reqWorkTypes] = useGetWorkTypesMutation();
  const [reqEducTypes] = useGetEducationsMutation();
  const [reqCompTypes] = useGetCompensationsMutation();
  const [reqEmpTypes] = useGetEmpTypeMutation();
  const [reqLocation] = useClientLocationMutation();
  const [reqSpecs] = useGetJobSpecsMutation();
  const [reqCountry] = useGetCountriesMutation();
  const [reqExpLevel] = useGetExperienceLevelMutation();

  useEffect(() => {
    if (jobId !== null || jobFormData?.job_id !== null) {
      const load = async () => {
        const paramJobId = jobId || jobFormData?.job_id;
        await reqGetJob({ id: Number(paramJobId) });
      };
      load();
    }
  }, [jobId, jobFormData?.job_id]);

  const auth = authStorage();

  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  const jobSpec = job_specs
    .filter((data: any) => data.id === jobData?.client?.spec_id)
    .map((data: { specification: string; id: number }) => ({
      label: data.specification,
      value: data.id,
    }))[0];

  const [contactOptionsEmail, setContactOptionsEmail] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState<any>({});
  const [selectedCompanyName, setSelectedCompanyName] = useState<any>(
    jobData?.client?.name || ''
  );
  const [selectedJobSpec, setSelectedJobSpec] = useState<any>(
    jobSpec || jobFormData.industry || []
  );

  const [ccOption, setCcOption] = useState([]);
  const [bccOption, setBccOption] = useState([]);

  const placeholderOptions = [
    'client_job_title',
    'job_posting_title',
    'candidate_first_name',
    'job_url',
    'job_name_url',
  ];

  useEffect(() => {
    if (isOpen) {
      const candidate_first_name = 'Harvey';
      const init_url = `${CAREER_PAGE_URL}/job-details`;
      let job_url = `<a href="${init_url}" style="color: blue; text-decoration: underline" target="_blank">${init_url}</a>`;
      let job_name_url = '';
      let client_job_title = '';
      let job_posting_title = '';
      if (jobId !== null || jobFormData?.job_id !== null) {
        client_job_title = jobData?.title || '';
        if (jobData?.jobAds?.length > 0) {
          const ads = jobData?.jobAds[0];
          if (ads) {
            const url = `${init_url}/${ads?.id}`;
            job_url = `<a href="${url}" style="color: blue; text-decoration: underline" target="_blank">${url}</a>`;
            job_name_url = `<a href="${url}" style="color: blue; text-decoration: underline" target="_blank">${ads.title}</a>`;
            job_posting_title = ads.title || client_job_title;
          }
        }
      }

      const jobParam = {
        client_job_title: client_job_title,
        candidate_first_name,
        job_url,
        job_name_url,
        job_posting_title,
      } as JobPairingIntParam;

      jobPairing(jobParam);
    }
  }, [isOpen, jobData]);

  const jobPairing = async (param: JobPairingIntParam) => {
    const newpair = {
      client_job_title: param.client_job_title,
      candidate_first_name: param.candidate_first_name,
      job_url: param.job_url,
      job_name_url: param.job_name_url,
      job_posting_title: param.job_posting_title,
    };

    setJobPairTemplate(newpair);
  };

  const placholders = (text: string) => {
    return replacePlaceholders(text, jobPairTemplate);
  };

  const placeHolderClick = (holder: string) => {
    navigator.clipboard.writeText(`{{${holder}}}`);
    toast({
      title: 'Placeholder copied',
      duration: 1000,
      position: 'top',
    });
  };

  const jobSpecsOption =
    job_specs.map((data: { specification: string; id: number }) => ({
      label: data.specification,
      value: data.id,
    })) || [];

  const handleJobSpec = (e: any) => {
    setSelectedJobSpec(e);
  };

  const jobSkills =
    (isEditModal || duplicate) && jobData
      ? jobData.skills?.map((skill: { skill: string; skill_id: number }) => ({
          label: skill.skill,
          value: skill.skill_id,
        })) || []
      : [];

  const jobAds =
    (isEditModal || duplicate) && jobData && jobData?.jobAds?.length > 0
      ? jobData.jobAds?.map((ad: any) => {
          return {
            title: ad.title,
            state: ad.state,
            city: ad.city,
            country: (countries?.length > 0 &&
              countries
                .map((item: any) => ({
                  label: item.country,
                  value: item.id,
                  code: item.code,
                }))
                .filter((val: any) => val.label === ad.country))[0],
            zip_code: ad.zip_code,
            description: ad.description,
            career_page_publish: ad.career_page_publish,
            show_profile: ad.show_profile,
            free_job_board_publish: ad.free_job_board_publish,
            paid_job_board_publish: ad.paid_job_board_publish,
            active: ad.active,
            worktype_id: ad.worktype_id,
            id: ad.id,
          };
        })
      : [
          {
            title: '',
            state: client_location[0]?.state || '',
            country: (countries?.length > 0 &&
              countries
                .map((item: any) => ({
                  label: item.country,
                  value: item.id,
                  code: item.code,
                }))
                .filter(
                  (val: any) =>
                    val.label === client_location[0]?.country?.country
                ))[0],
            city: client_location[0]?.city || '',
            zip_code: client_location[0]?.zip_code || '',
            description: '',
            career_page_publish: true,
            show_profile: false,
            free_job_board_publish: true,
            paid_job_board_publish: false,
            active: 1,
            worktype_id: jobFormData?.work_type_id || null,
            id: null,
          },
        ];

  const recuiterPopulate = (recruiters: any) => {
    try {
      let data: any = [];
      recruiters.map((item: any) => {
        data.push({
          name: item.recruiter_id,
          split_percentage: item.split,
          post_new_job_ads: item.post_new_job_ads,
          no_submittal_review: item.no_submittal_review,
          review_send_as_client_owner: item.review_send_as_client_owner,
          review_send_as_client_recruiter: item.review_send_as_client_recruiter,
          interview_send_as_client_owner: item.review_send_as_client_owner,
          interview_send_as_recruiter: item.interview_send_as_recruiter,
        });
      });
      return data;
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    if (companyDef.value) {
      fetchLocation(companyDef.value);
      setCompanyOption(contactOptionValue);
    }
  }, [companyDef]);

  // Fetch Client Locations when job modal refreshes
  useEffect(() => {
    if (jobFormData?.company?.value && locationOption.length == 0) {
      fetchLocation(jobFormData?.company?.value);
    }
  }, [jobFormData?.company?.value]);

  useEffect(() => {
    if (jobData?.client_id !== undefined) {
      fetchLocation(jobData.client_id);
      const company = allClient.filter((obj: any) => {
        return obj.id === jobData.client_id;
      });
      let options: any = [];
      let optionsEmails: any = [];

      if (company[0] !== undefined) {
        if (company[0].leads?.length > 0) {
          let emails = [] as any;
          // await Promise.all(
          company[0].leads.map(async (item: any) => {
            options.push({
              label: [item.first_name, item.last_name]
                .filter(Boolean)
                .join(' '),
              value: item.id,
            });
            optionsEmails.push({
              label: [item.first_name, item.last_name]
                .filter(Boolean)
                .join(' '),
              value: item.primary_email,
            });
          });
        }
      }

      setContactOption(options);
      setCcOption(optionsEmails);
      setBccOption(optionsEmails);
      setContactOptionsEmail(optionsEmails);
    }
  }, [jobData, allClient]);

  const ccBc = (emails: any) => {
    let options: any = [];
    try {
      emails.filter(Boolean).map((email: any) => {
        let label = '';
        const company = allClient.filter((obj: any) => {
          return obj.id === jobData.client_id;
        });
        if (company[0] !== undefined) {
          if (company[0].leads?.length > 0) {
            company[0].leads.map(async (item: any) => {
              if (item.primary_email === email) {
                label = String(
                  [item.first_name, item.last_name].filter(Boolean).join(' ')
                );
              }
            });
          }
        }

        if (label === '') {
          Object.values(userList).map((user: any) => {
            if (user.email === email) {
              label = String(
                [user.first_name, user.last_name].filter(Boolean).join(' ')
              );
            }
          });
        }
        options.push({
          label: label,
          value: email,
        });
      });
    } catch (e) {
      console.warn('e', e);
    }

    return options;
  };

  const setBcc = (jobData: any) => {
    if (jobData?.bcc?.bcc) {
      return ccBc(jobData?.bcc?.bcc);
    }
    return [];
  };

  const setCc = (jobData: any) => {
    if (jobData?.bcc?.cc) {
      return ccBc(jobData?.bcc?.cc);
    }
    return [];
  };

  // FIRST INITIALIZE OF EDIT MODAL
  // LOAD VALUES TO SETFORMDATA

  const [initialClientValues, setInitialClientValue] = useState<any>({
    // client
    job_type_id: 1,
    client_job_link: '',
    industry: selectedJobSpec,
    company: selected_client.company || companyDef,
    contact: selected_client.contact || selected_contact || contactDef,
    location: selected_client_location,
    cc: [] as any,
    bcc: [] as any,
    job_status_id: 1,
  });

  const [initialJobAdsValues, setInitialJobAdsValues] = useState<any>([
    {
      title: 'initial 769',
      state: client_location[0]?.state || '',
      country: (countries?.length > 0 &&
        countries
          .map((item: any) => ({
            label: item.country,
            value: item.id,
            code: item.code,
          }))
          .filter(
            (val: any) => val.label === client_location[0]?.country?.country
          ))[0],
      city: client_location[0]?.city || '',
      zip_code: client_location[0]?.zip_code || '',
      description: jobAdDescTemplate,
      career_page_publish: true,
      show_profile: false,
      free_job_board_publish: true,
      paid_job_board_publish: false,
      active: 1,
      worktype_id: jobFormData?.work_type_id || null,
      id: null,
    },
  ]);

  const [initialValues, setInitialValues] = useState<any>({
    // client
    ...initialClientValues,
    primary_recruiter:
      jobFormData.primary_recruiter ||
      jobData?.primary?.recruiter_id ||
      auth?.id,
    am_id: aMOption?.length > 0 ? aMOption[0]?.value : ('' as any),
    title: '',
    opening: 1,
    experience_level: undefined as any,
    //
    education_id: undefined as any,
    work_type_id: undefined as any,
    client_job_link: '',
    description: '',

    // skills: [] as any,
    notes: jobFormData?.notes || '',
    //
    employment_type_id: 1,
    compensation_id: 2,
    campaign: true,
    salary_range_start: 0,
    salary_range_end: 0,

    // rec
    recruiters: [] as any,
    // job ads
    job_ads: initialJobAdsValues,

    // customize pitch
    email_subject: String(defaultPitch?.[0]?.subject) || '',
    email_content: String(defaultPitch?.[0]?.content) || '',
    // not include
    status: 1, // should not include in create job
    lead_id: 1, // should not include in create job
  });

  useEffect(() => {
    if (isEditModal || duplicate) {
      if (jobData && Object.keys(jobData).length !== 0) {
        const def = {
          // client
          job_type_id: jobData?.job_type_id,
          company: {
            label: jobData?.client?.name,
            value: jobData?.client_id,
          },
          contact: {
            label: jobData.lead?.first_name + ' ' + jobData.lead?.last_name,
            value: jobData.lead_id,
          },
          industry: jobSpec,
          location: {
            label: [
              jobData.location?.address,
              jobData.location?.city,
              jobData.location?.state,
              jobData.location?.zip_code,
              jobData.location?.country?.country,
            ]
              .filter((addressPart) => addressPart !== '')
              .join(', '),
            value: jobData.location_id,
          },
          cc: setCc(jobData),
          bcc: setBcc(jobData),
          job_status_id: jobData.job_status_id || 1,
        };
        setSelectedCompanyName(jobData?.client?.name);
        setInitialClientValue(def);
        const job_ads = jobAds || [
          {
            title: '',
            state: client_location[0]?.state || '',
            country: (countries?.length > 0 &&
              countries
                .map((item: any) => ({
                  label: item.country,
                  value: item.id,
                  code: item.code,
                }))
                .filter(
                  (val: any) =>
                    val.label === client_location[0]?.country?.country
                ))[0],
            city: client_location[0]?.city || '',
            zip_code: client_location[0]?.zip_code || '',
            description: jobAdDescTemplate,
            career_page_publish: true,
            show_profile: false,
            free_job_board_publish: true,
            paid_job_board_publish: false,
            active: 1,
            worktype_id: jobFormData?.work_type_id || null,
            id: null,
          },
        ];
        setInitialJobAdsValues({ job_ads });

        const initial = {
          ...def,
          primary_recruiter: jobData.primary?.recruiter_id || auth?.id,
          am_id:
            jobData.am_id || aMOption?.length > 0
              ? aMOption[0]?.value
              : ('' as any),
          title: jobData.title,
          opening: jobData.opening === -1 ? 1 : jobData.opening,
          experience_level:
            jobData.experience_level === -1 ? -1 : jobData.experience_level?.id,
          education_id: jobData.education_id || -1,
          work_type_id: jobData.work_type_id,
          client_job_link:
            jobData.client_job_link ||
            jobData.client_job_link?.replace(/(^\w+:|^)\/\//, ''),
          description: jobData.description || '',
          notes: '',
          employment_type_id: jobData.employment_type_id || 1,
          compensation_id: jobData.compensation_id || 2,
          campaign: jobData.is_campaign || true,
          salary_range_start:
            jobData.salary_range_start === -1
              ? 0
              : jobData.salary_range_start?.toLocaleString(),
          salary_range_end:
            jobData.salary_range_end === -1
              ? 0
              : jobData.salary_range_end?.toLocaleString(),
          recruiters: recuiterPopulate(jobData.recruiters) || [],
          job_ads: job_ads,

          // customize pitch
          email_subject:
            jobData?.pitch?.subject ?? String(defaultPitch?.[0]?.subject) ?? '',
          email_content:
            jobData?.pitch?.content ?? String(defaultPitch?.[0]?.content) ?? '',

          // not include
          status: jobData.status,
          lead_id: jobData.lead_id,
        };

        setInitialValues(initial);
        setJobFormData((prev: any) => ({
          ...prev,
          ...initial,
          job_id: jobData.id,
        }));
      }
    }
  }, [jobData, resCompany.isSuccess, allClient]);

  useEffect(() => {
    if (jobFormData && Object.keys(jobFormData).length !== 0) {
      const def = {
        // client
        job_type_id: Number(jobFormData.job_type_id),
        company: jobFormData.company,
        contact: jobFormData.contact,
        industry: jobFormData.industry,
        location: jobFormData.location,
        cc: jobFormData.cc ? jobFormData.cc : ([] as any),
        bcc: jobFormData.bcc ? jobFormData.bcc : ([] as any),
        job_status_id: Number(jobFormData.job_status_id) || 1,
      };
      setInitialClientValue(def);
      const job_ads = jobFormData.job_ads ||
        jobAds || [
          {
            title: '',
            state: client_location[0]?.state || '',
            country: (countries?.length > 0 &&
              countries
                .map((item: any) => ({
                  label: item.country,
                  value: item.id,
                  code: item.code,
                }))
                .filter(
                  (val: any) =>
                    val.label === client_location[0]?.country?.country
                ))[0],
            city: client_location[0]?.city || '',
            zip_code: client_location[0]?.zip_code || '',
            description: jobAdDescTemplate,
            career_page_publish: true,
            show_profile: false,
            free_job_board_publish: true,
            paid_job_board_publish: false,
            active: 1,
            worktype_id: jobFormData?.work_type_id || null,
            id: null,
          },
        ];
      setInitialJobAdsValues({ job_ads });

      const initial = {
        ...def,
        primary_recruiter:
          jobFormData.primary_recruiter ||
          jobData.primary?.recruiter_id ||
          auth?.id,
        am_id: jobFormData.am_id,
        title: jobFormData.title,
        opening: jobFormData.opening,
        experience_level: jobFormData.experience_level,
        //
        education_id: jobFormData.education_id,
        work_type_id: jobFormData.work_type_id,
        client_job_link: jobFormData.client_job_link,
        description: jobFormData.description,
        notes: jobFormData?.notes || '',
        //
        employment_type_id: jobFormData.employment_type_id,
        compensation_id: jobFormData.compensation_id,
        campaign: jobFormData.campaign,
        salary_range_start: jobFormData.salary_range_start,
        salary_range_end: jobFormData.salary_range_end,
        recruiters: jobFormData.recruiters || [],
        // job ads
        job_ads: job_ads,
        // customize pitch
        email_subject:
          jobFormData.email_subject || String(defaultPitch?.[0]?.subject) || '',
        email_content:
          jobFormData.email_content || String(defaultPitch?.[0]?.content) || '',

        // not include
        status: 1,
        lead_id: 1,
      };

      setInitialValues(initial);
    }
  }, [jobFormData]);

  const initialJobDetailsValues =
    (isEditModal || duplicate) &&
    ((jobData && Object.keys(jobData).length !== 0) ||
      (jobFormData && Object.keys(jobFormData).length !== 0))
      ? {
          // job details
          job_type_id: jobFormData.job_type_id || jobData.job_type_id,
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id:
            jobFormData.am_id || jobData.am_id || aMOption?.length > 0
              ? aMOption[0]?.value
              : ('' as any),
          title: jobFormData.title || jobData.title,
          opening:
            jobFormData.opening || jobData.opening === -1
              ? jobFormData.opening || 1
              : jobData.opening,
          experience_level:
            jobFormData.experience_level || jobData.experience_level === -1
              ? jobFormData.experience_level || null
              : jobData.experience_level?.id,
          //
          education_id: jobFormData.education_id || jobData.education_id || -1,
          work_type_id: jobFormData.work_type_id || jobData.work_type_id,
          client_job_link:
            jobFormData.client_job_link ||
            jobData.client_job_link?.replace(/(^\w+:|^)\/\//, ''),
          description: jobFormData.description || jobData.description || '',

          skills: jobFormData.skills || jobSkills,
          notes: jobFormData.notes || '',
          //
          employment_type_id:
            jobFormData.employment_type_id || jobData.employment_type_id || 1,
          compensation_id:
            jobFormData.compensation_id || jobData.compensation_id || 2,
          campaign: jobFormData.campaign || jobData.is_campaign || true,
          salary_range_start:
            jobFormData.salary_range_start || jobData.salary_range_start === -1
              ? jobFormData.salary_range_start || 0
              : jobData.salary_range_start?.toLocaleString(),
          salary_range_end:
            jobFormData.salary_range_end || jobData.salary_range_end === -1
              ? jobFormData.salary_range_end || 0
              : jobData.salary_range_end?.toLocaleString(),
          job_status_id:
            jobFormData.job_status_id || jobData.job_status_id || 1,
        }
      : jobFormData && Object.keys(jobFormData).length !== 0
      ? {
          // job details
          job_type_id: jobFormData.job_type_id,
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id:
            jobFormData.am_id || aMOption?.length > 0
              ? aMOption[0]?.value
              : ('' as any),
          title: jobFormData.title,
          opening: jobFormData.opening,
          experience_level: jobFormData.experience_level,
          //
          education_id: jobFormData.education_id,
          work_type_id: jobFormData.work_type_id,
          client_job_link: jobFormData.client_job_link,
          description: jobFormData.description || '',

          skills: jobFormData.skills,
          notes: jobFormData?.notes || '',
          //
          employment_type_id: jobFormData.employment_type_id,
          compensation_id: jobFormData.compensation_id,
          salary_range_start: jobFormData.salary_range_start,
          salary_range_end: jobFormData.salary_range_end,
          job_status_id: jobFormData.job_status_id || 1,
          campaign: jobFormData.campaign || true,
        }
      : {
          // job details
          job_type_id: jobFormData?.job_type_id || 1,
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id: aMOption?.length > 0 ? aMOption[0]?.value : ('' as any),
          title: '',
          opening: 1,
          experience_level: undefined as any,
          //
          education_id: undefined as any,
          work_type_id: undefined as any,
          client_job_link: '',
          description: '',

          skills: jobFormData.skills || jobSkills || [],
          notes: jobFormData?.notes || '',
          //
          employment_type_id: 1,
          compensation_id: 2,
          campaign: true,
          salary_range_start: 0,
          salary_range_end: 0,
          job_status_id: 1,
        };

  const initialRecruitersValues =
    (isEditModal || duplicate) &&
    ((jobData && Object.keys(jobData).length !== 0) ||
      (jobFormData && Object.keys(jobFormData).length !== 0))
      ? {
          // rec
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id: jobFormData.am_id || jobData?.am_id,
          job_type_id: jobFormData.job_type_id || jobData.job_type_id || 1,
          recruiters:
            jobFormData.recruiters ||
            recuiterPopulate(jobData.recruiters) ||
            ([] as any),
          job_status_id:
            jobFormData.job_status_id || jobData.job_status_id || 1,
        }
      : jobFormData && Object.keys(jobFormData).length !== 0
      ? {
          // rec
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id:
            jobFormData.am_id || aMOption?.length > 0 ? aMOption[0]?.value : '',
          job_type_id: jobFormData.job_type_id || 1,
          recruiters: jobFormData.recruiters || ([] as any),
          job_status_id: jobFormData.job_status_id || 1,
        }
      : {
          // rec
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id: aMOption?.length > 0 ? aMOption[0]?.value : ('' as any),
          job_type_id: jobFormData?.job_type_id || 1,
          recruiters: [] as any,
          job_status_id: 1,
        };

  const [hasJobPostType, setHasJobPostType] = useState(
    String(initialValues.job_type_id)
  );

  const [jobStatus, setJobStatus] = useState(
    (jobFormData || initialValues).job_status_id
  );

  useEffect(() => {
    setHasJobPostType(String(initialValues.job_type_id));
  }, [initialValues.job_type_id]);

  const emailSchema = Yup.string().email('Invalid email format');

  const validationSchema = Yup.lazy((values) => {
    var currentJobStatus = values.job_status_id;
    setJobStatus(currentJobStatus);
    if (currentJobStatus === 5) return Yup.mixed().notRequired();
    return hasJobPostType === '1'
      ? Yup.object().shape({
          // client
          job_type_id: Yup.number().required('Job type is required.'),
          company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
          contact: Yup.array().min(1, 'Pick at least 1 contact').nullable(),
          industry: Yup.array().min(1, 'Pick at least 1 industry').nullable(),
          location: Yup.array().min(1, 'Pick at least 1 location').nullable(),
          cc: Yup.array()
            .nullable()
            .of(
              Yup.object().shape({
                label: Yup.string().when('$isLabelProvided', {
                  is: true,
                  then: emailSchema,
                  otherwise: Yup.string(),
                }),
                id: Yup.string(),
                __isNew__: Yup.boolean(),
              })
            ),
          bcc: Yup.array()
            .nullable()
            .of(
              Yup.object().shape({
                label: Yup.string().when('$isLabelProvided', {
                  is: true,
                  then: emailSchema,
                  otherwise: Yup.string(),
                }),
                id: Yup.string(),
                __isNew__: Yup.boolean(),
              })
            ),
          // job details
          primary_recruiter: Yup.number().required(
            'Primary Recruiter is required.'
          ),
          am_id: Yup.number().required('Account Manager is required.'),
          title: Yup.string().required('Job title is required.'),
          opening: Yup.number()
            .required('No. of openings is required.')
            .min(1, 'Invalid number.'),
          experience_level: Yup.number()
            .required('Experience level is required.')
            .min(1, 'Invalid level.'),
          //
          education_id: Yup.number().required('Education is required.'),
          work_type_id: Yup.number().required('Work type is required.'),
          client_job_link: Yup.string(),
          description: Yup.string(),

          skills: Yup.array().of(Yup.string()),
          notes: Yup.string(),
          //
          employment_type_id: Yup.number().required(
            'Employment type is required.'
          ),
          compensation_id: Yup.number(),
          campaign: Yup.boolean(),
          salary_range_start: Yup.string()
            .matches(/^[\d,]+$/gi, 'Salary is invalid')
            .required('Salary range start is required.'),
          salary_range_end: Yup.string()
            .matches(/^[\d,]+$/gi, 'Salary is invalid')
            .required('Salary range end is required.'),

          // recruiters
          recruiters: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Recruiter is required'),
              split_percentage: Yup.number().required(
                'Split percentage is required'
              ),
              post_new_job_ads: Yup.boolean(),
              no_submittal_review: Yup.boolean(),
              review_send_as_client_owner: Yup.boolean(),
              review_send_as_client_recruiter: Yup.boolean(),
              interview_send_as_client_owner: Yup.boolean(),
              interview_send_as_recruiter: Yup.boolean(),
            })
          ),
          // job ads
          job_ads: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required('Posting Title is required'),
              state: Yup.string().required('State is required'),
              city: Yup.string().required('City is required'),
              zip_code: Yup.string().required('Zip Code is required'),
              country: Yup.object().required('Country is required').nullable(),
              description: Yup.string().required('Description is required.'),
              career_page_publish: Yup.boolean(),
              show_profile: Yup.boolean(),
              free_job_board_publish: Yup.boolean(),
              paid_job_board_publish: Yup.boolean(),
              active: Yup.number(),
              worktype_id: Yup.number().required('Work Type is required'),
              id: Yup.number().nullable(),
            })
          ),

          // customize pitch
          emaiL_subject: Yup.string(),
          email_content: Yup.string(),

          // not include
          status: Yup.number().required('Status is required.'),
          lead_id: Yup.number().required('Lead is required.'),
          job_status_id: Yup.number().required('Job status is required.'),
        })
      : Yup.object().shape({
          //client
          job_type_id: Yup.number().required('Job type is required.'),
          company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
          contact: Yup.array().min(1, 'Pick at least 1 contact').nullable(),
          industry: Yup.array().min(1, 'Pick at least 1 industry').nullable(),
          location: Yup.array().min(1, 'Pick at least 1 location').nullable(),
          cc: Yup.array().of(
            Yup.object().shape({
              label: Yup.string().when('$isLabelProvided', {
                is: true,
                then: emailSchema,
                otherwise: Yup.string(),
              }),
              id: Yup.string(),
              __isNew__: Yup.boolean(),
            })
          ),
          bcc: Yup.array().of(
            Yup.object().shape({
              label: Yup.string().when('$isLabelProvided', {
                is: true,
                then: emailSchema,
                otherwise: Yup.string(),
              }),
              id: Yup.string(),
              __isNew__: Yup.boolean(),
            })
          ),

          //job details
          primary_recruiter: Yup.number().required(
            'Primary Recruiter is required.'
          ),
          am_id: Yup.number().required('Account Manager is required.'),
          title: Yup.string().required('Job title is required.'),
          experience_level: Yup.number()
            .required('Experience level is required.')
            .min(1, 'Invalid number.'),
          skills: Yup.array().of(Yup.string()),
          notes: Yup.string(),
          client_job_link: Yup.string(),

          // customize pitch
          emaiL_subject: Yup.string(),
          email_content: Yup.string(),

          // not necessary
          status: Yup.number().required('Status is required.'),
          lead_id: Yup.number().required('Lead is required.'),
        });
  });

  const tabListIndex: any = [
    { id: 0, title: 'Client Details' },
    { id: 1, title: 'Job Details' },
    {
      id: 2,
      title: hasJobPostType === '2' ? 'Customize Pitch' : 'Recruiting Team',
    },
    { id: 3, title: 'Job Ads' },
    { id: 4, title: 'Customize Pitch' },
  ];

  const [tabList, setTabList] = useState(tabListIndex);
  const [tabIndex, setTabIndex] = useState(tabList[0]);
  const [errorList, setErrorList] = useState({});

  const [isDraft, setIsDraft] = useState(false);

  const [protocol, setProtocol] = useState({
    value: 'https://',
    label: 'https://',
  });

  // useEffect(() => {
  //   if (tabIndex.id === 1) {
  //     // Focus on the topComponent element after the component has mounted
  //     document.getElementById('title').focus();
  //   }
  // }, [tabIndex.id]);

  useEffect(() => {
    if (hasJobPostType === '2') {
      setTabList(tabListIndex.slice(0, 3));
    } else {
      return setTabList(tabListIndex);
    }
  }, [hasJobPostType]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const changeToDefault = (data: any) => {
    data = {
      ...data,
      job_status_id: 5,
      company:
        data.company?.length === 0
          ? (data.company = { value: null, label: ' ' })
          : data.company,
      contact:
        data.contact?.length === 0
          ? (data.contact = { value: 1, label: ' ' })
          : data.contact,
      title: data.title === undefined ? '' : data.title,
      opening: data.opening === undefined ? 1 : data.opening,
      experience_level:
        data.experience_level === undefined ? null : data.experience_level,
      skills: data.skills === undefined ? [] : data.skills,
      notes: data.notes,
      work_type_id: data.work_type_id === undefined ? null : data.work_type_id,
      employment_type_id:
        data.employment_type_id === undefined ? 1 : data.employment_type_id,
      compensation_id:
        data.compensation_id === undefined ? 2 : data.compensation_id,
      salary_range_start:
        data.salary_range_start === undefined ? -1 : data.salary_range_start,
      salary_range_end:
        data.salary_range_end === undefined ? -1 : data.salary_range_end,
    };
    if (data.job_type_id === '1') {
      data = {
        ...data,
        recruiters: data.recruiters.length === 0 ? [] : data.recruiter,
        job_ads: data.job_ads.length === 0 ? [] : data.job_ads,
      };
    }

    return data;
  };

  const onSubmit = async (data: any) => {
    data.company = {
      value: data.company.value,
      label: data.company.label,
    };
    data.spec_id = data?.industry?.value;
    data.location = data.location.value;
    data.education_id = data.education_id === -1 ? 1 : data.education_id;
    data.employment_type_id =
      data.employment_type_id === -1 ? 1 : data.employment_type_id;
    data.compensation_id =
      data.compensation_id === -1 ? 2 : data.compensation_id;

    if (data.notes !== '') {
      const notes = document.createElement('p');
      notes.innerHTML = data.notes;
      notes.style.fontFamily = 'Verdana';
      notes.style.fontSize = '10pt';
      notes.style.lineHeight = 'normal';
      data.notes = String(notes.outerHTML);
    }

    data.questions = []; // TODO: Temporary

    if (
      data.email_content &&
      data.email_subject &&
      (data.email_content !== '' || data.email_subject !== '')
    ) {
      const customize_pitch = {
        email_content: data.email_content,
        email_subject: data.email_subject,
      };
      data['customize_pitch'] = customize_pitch;
    }

    delete data.industry;
    delete data.email_content;
    delete data.email_subject;

    if (data.job_ads) {
      data.job_ads[0].country = data.job_ads[0]?.country?.label;
      data.job_ads[0].title = placholders(data.job_ads[0].title);
      data.job_ads[0].description = placholders(data.job_ads[0].description);
    }

    if (data.client_job_link) {
      data.client_job_link =
        protocol.value + data.client_job_link?.replace(/(^\w+:|^)\/\//, '');
    }

    let cc: any = [];
    if (data.cc.length > 0) {
      data.cc.map((item: any) => {
        if (typeof item === 'object') {
          cc.push(item.value);
        } else {
          cc.push(item.label);
        }
      });
    }
    data.cc = cc;
    let bcc: any = [];
    if (data.bcc.length > 0) {
      data.bcc.map((item: any) => {
        if (typeof item === 'object') {
          bcc.push(item.value);
        } else {
          bcc.push(item.label);
        }
      });
    }
    data.bcc = bcc;
    if (hasJobPostType === '2') {
      delete data.job_type_id;
      delete data.questions;
      delete data.job_ads;
      delete data.description;
      delete data.recruiters;
      delete data.opening;
      delete data.education_id;
      delete data.employment_type_id;
      delete data.salary_range_end;
      delete data.salary_range_start;
      delete data.work_type_id;
      delete data.compensation_id;
    } else {
      data.job_type_id = Number(data.job_type_id);
      data.salary_range_start =
        Number(String(data.salary_range_start)?.replace(/,/g, '')) || -1;
      data.salary_range_end =
        Number(String(data.salary_range_end)?.replace(/,/g, '')) || -1;
    }
    if (
      hasJobPostType === '1' &&
      data.recruiters &&
      data.recruiters?.length > 0
    ) {
      data.primary_recruiter = data.primary_recruiter;
      data.recruiters.map((item: any, key: number) => {
        let index = `recruiters${key}`;
        delete data[index]; //remove unwanted index
      });
    }

    localStorage.removeItem('jobFormData');

    delete data.job_id;

    let skills: Array<string> = [];

    interface skillInt {
      label: string;
      value: number | string;
    }
    await Promise.all(
      data?.skills?.map((skill: skillInt) => {
        skills.push(skill.label);
      })
    );

    data = {
      ...data,
      skills,
    };

    if (isEditModal) {
      if (hasJobPostType === '2') {
        reqUpdateQuick({ data, id: jobData.id || jobFormData.job_id });
      } else {
        reqUpdateJob({ data, id: jobData.id || jobFormData.job_id });
      }
    } else if (hasJobPostType === '2') {
      reqCreateQuick(data);
    } else {
      reqCreateJob(data);
    }
  };

  const loadConstant = () => {
    reqUsers({});
    reqCountry({});
    reqSpecs({});
    reqWorkTypes({});
    reqEducTypes({});
    reqCompTypes({});
    reqEmpTypes({});
  };

  useEffect(() => {
    if (isOpen) {
      loadConstant();
      dispatch(getJob({ hasPaidJob: false }));
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCompanyName?.length >= 3) {
      const payload = {
        ...allClientPageBtn,
        query: selectedCompanyName,
      };
      reqcompany({ data: payload });
    }
  }, [selectedCompanyName]);

  useEffect(() => {
    if (
      resCreateJob.isSuccess ||
      resUpdateJob.isSuccess ||
      resCreateQuick.isSuccess ||
      resUpdateQuick.isSuccess
    ) {
      {
        onCloseAlert();
        onClose();

        setSelectedCompanyName('');

        if (resCreateJob.isSuccess || resCreateQuick.isSuccess) {
          dispatch(getJob({ jobData: {} }));
          dispatch(
            listClients({
              selected_client: [],
              selected_client_location: [],
              client_location_obj: {
                city: '',
                state: '',
                zip_code: '',
                country: '',
              },
            })
          );
          dispatch(getLead({ selected_contact: [] }));
        }

        if (!isDrawer) {
          reqJobs({ data: jobPgBtn });
        } else if (params.clientsTab) {
          reqClientJobs({ data: jobPgBtn, id: params.clientsId });
        }

        let jobData = null;

        if (resCreateJob.isSuccess) {
          jobData = resCreateJob?.data?.data;
        }
        if (resUpdateJob.isSuccess) {
          jobData = resUpdateJob?.data?.data;
        }
        if (resCreateQuick.isSuccess) {
          jobData = resCreateQuick?.data?.data;
        }
        if (resUpdateQuick.isSuccess) {
          jobData = resUpdateQuick?.data?.data;
        }

        if (jobData?.jobAds) {
          dispatch(
            getJob({ hasPaidJob: jobData?.jobAds[0]?.paid_job_board_publish })
          );
        }

        navigate(`/jobs/${jobData.data?.id}/details`);

        try {
          let title = '';
          let description = '';
          let status: 'error' | 'warning' | 'success' | 'info' = 'success';

          if (resCreateJob.isSuccess && jobStatus === 1) {
            title = 'Added Job';
            description = 'New Job successfully added';
          } else if (resCreateJob.isSuccess && jobStatus === 5) {
            title = 'Saved Job to Draft';
            description = 'Job has been saved to draft';
          } else if (resUpdateJob.isSuccess) {
            title = 'Updated Job';
            description = 'Job has been updated';
            status = 'info';
          } else if (resCreateQuick.isSuccess && jobStatus === 1) {
            title = 'Added Job Quick';
            description = 'New Job Quick successfully added';
          } else if (resCreateQuick.isSuccess && jobStatus === 5) {
            title = 'Saved Job Quick to Draft';
            description = 'Job Quick has been saved to draft';
          } else if (resUpdateQuick.isSuccess) {
            title = 'Updated Job Quick';
            description = 'Job Quick has been updated';
            status = 'info';
          }

          toast({
            title: title,
            description: description,
            status: status,
            isClosable: true,
            duration: 3000,
            position: 'top',
          });
        } catch (e) {
          console.log('error', e);
        }
      }
    }
  }, [
    resCreateJob.isSuccess,
    resUpdateJob.isSuccess,
    resCreateQuick.isSuccess,
    resUpdateQuick.isSuccess,
  ]);

  useEffect(() => {
    if (
      resCreateJob.isError ||
      resUpdateJob.isError ||
      resCreateQuick.isError ||
      resUpdateQuick.isError
    ) {
      onOpen();
    }
    if (resCreateJob.isError) {
      let errorMessage: any = resCreateJob.error;
      if (errorMessage['data']['error'] !== undefined) {
        setErrorMsg(errorMessage['data']['error']['message']);
      } else {
        try {
          setErrorMsg(errorMessage['data']['message']);
        } catch (e) {
          setErrorMsg('Server Error');
        }
      }
    }
  }, [
    resCreateJob.error,
    resCreateJob.isError,
    resCreateQuick.isError,
    resUpdateJob.isError,
    resUpdateQuick.isError,
  ]);

  useEffect(() => {
    let users: any = [];
    if (resUsers.isSuccess) {
      if (resUsers.data?.data.length > 0) {
        Promise.all(
          resUsers.data?.data.map((item: any) => {
            users.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
          })
        );
      }
    }
    setRecruiters(users);
  }, [resUsers.data?.data, resUsers.isSuccess]);

  useEffect(() => {
    reqExpLevel({});

    if (resCompany.isSuccess && contactOptionValue.length === 0) {
      const company = resCompany.data.data.data;
      let comOption: any = [];
      if (company.length > 0) {
        company.map((option: any) => {
          comOption.push({
            value: option.id,
            label: option.name,
            industry: option.specs,
          });
        });
        setCompanyOption(comOption);
      }
      if (initialValues.company?.length > 0) {
        setContact(initialValues.company[0].value);
      }

      if (jobData?.client_id) {
        setManager(jobData.client_id);
      }
    }
  }, [resCompany.isSuccess]);

  const CompanyChange = async (e: any) => {
    setSelectedCompany(e);
    if (e?.industry) {
      setSelectedJobSpec({
        value: e?.industry?.id,
        label: e?.industry?.specification,
      });
    }
    setContact(e.value);
    setManager(e.value);
  };

  const protocolRegex = /^(.*?:\/\/)/;

  const handleProtocol = (value: string) => {
    const match = value.match(protocolRegex);

    if (match) {
      const protocol = match[1];
      setProtocol({
        value: protocol,
        label: protocol,
      });
    }
  };

  useEffect(() => {
    if (jobData.client_job_link) {
      handleProtocol(jobData.client_job_link);
    }
  }, [jobData?.client_job_link]);

  useEffect(() => {
    if (jobData?.client_id) {
      setManager(jobData?.client_id);
      setContact(jobData?.client_id);
    }
  }, [jobData, allClient]);

  const setNewContactOptions = async (id: number, clientList: any) => {
    const company = clientList.filter((obj: any) => {
      return obj.id === id;
    });
    let options: any = [];
    let optionsEmails: any = [];
    if (company[0] !== undefined) {
      if (company[0].leads?.length > 0) {
        let emails = [] as any;
        await Promise.all(
          company[0].leads.map(async (item: any) => {
            options.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
            optionsEmails.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.primary_email,
            });
          })
        );
      }
    }

    setContactOption(options);
  };

  useEffect(() => {
    if (selectedCompany?.value) {
      const clientList = allClient;
      const clientId = selectedCompany?.value;
      setNewContactOptions(clientId, clientList);
    }
  }, [selected_contact]);

  useEffect(() => {
    const setManager = async (id: any) => {
      const company = allClient.filter((obj: any) => {
        return obj.id === id;
      });

      let options: any = [];
      if (company[0] !== undefined) {
        if (company[0].manager?.length > 0) {
          await Promise.all(
            company[0].manager.map(async (item: any) => {
              options.push({
                label: [item?.user?.first_name, item?.user?.last_name].join(
                  ' '
                ),
                value: item.user_id,
              });
            })
          );
        }
      }
      setAmOption(options);
    };
    if (jobFormData?.company?.value) {
      setManager(jobFormData?.company?.value);
    }
  }, [allClient, jobFormData?.company?.value]);

  const setManager = async (id: any) => {
    const company = allClient.filter((obj: any) => {
      return obj.id === id;
    });

    let options: any = [];
    if (company[0] !== undefined) {
      if (company[0].manager?.length > 0) {
        await Promise.all(
          company[0].manager.map(async (item: any) => {
            options.push({
              label: [item?.user?.first_name, item?.user?.last_name].join(' '),
              value: item.user_id,
            });
          })
        );
      }
    }
    setAmOption(options);
  };

  const abortLocationControllerRef = useRef<AbortController | null>(null);

  const fetchLocation = (id: any) => {
    if (abortLocationControllerRef.current) {
      abortLocationControllerRef.current.abort();
    }

    abortLocationControllerRef.current = new AbortController();
    reqLocation({ id, signal: abortLocationControllerRef.current.signal });
  };

  const setContact = async (id: any) => {
    const company = allClient.filter((obj: any) => {
      return obj.id === id;
    });
    let options: any = [];
    let optionsEmails: any = [];
    if (company[0] !== undefined) {
      if (company[0].leads?.length > 0) {
        let emails = [] as any;
        await Promise.all(
          company[0].leads.map(async (item: any) => {
            options.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
            optionsEmails.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.primary_email,
            });
          })
        );
      }
    }

    setContactOption(options);
    setContactOptionsEmail(optionsEmails);
    setCcOption(optionsEmails);
    setBccOption(optionsEmails);
    dispatch(setClientId({ client_id: id }));
    fetchLocation(id);
  };

  const populateUSers = () => {
    let emails = [] as any;

    Object.values(userList).map((item: any) => {
      emails.push({
        label: [item.first_name, item.last_name].filter(Boolean).join(' '),
        value: item.email,
      });
    });

    return emails;
  };

  useEffect(() => {
    const setLocation = async () => {
      let options: any = [];
      const address = client_location;

      if (address !== undefined) {
        if (address?.length > 0) {
          await Promise.all(
            address.map(async (item: any) => {
              options.push({
                label: [
                  item.address,
                  item.city,
                  item.state,
                  item.zip_code,
                  item.country?.country,
                ]
                  .filter((addressPart) => addressPart !== '')
                  .join(', '),
                value: item.id,
                data: item,
              });
            })
          );
        }
      }
      setLocationOption(options);
    };
    if (client_location) {
      setLocation();
    }
  }, [companyDef.value, client_location]);

  useEffect(() => {
    localStorage.setItem('jobFormData', JSON.stringify(jobFormData));
  }, [jobFormData]);

  const { geo_location } = useSelector((state: any) => state.geolocation);

  const [itemLocation, setItemLocation] = useState([]);
  const [reqGeo, resGeo] = useSearchGeoTypesMutation();
  const [currentName, setName] = useState('');

  const geoLocationChange = debounce((e: any, column: string) => {
    const search = e.target.value;
    if (search.length > 3) {
      const data = {
        search,
        column,
      };
      reqGeo(data);
    }
  }, 500);

  const [countryOption, setCountryOption] = useState([]);

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });
      });
    }
    setCountryOption(options);
  }, [countries]);

  const onCcInputChange = (e: any) => {
    if (e.length > 2) {
      const options = [...contactOptionsEmail, ...populateUSers()];
      setCcOption(options);
    } else {
      setCcOption(contactOptionsEmail);
    }
  };

  const onBccInputchange = (e: any) => {
    if (e.length > 2) {
      const options = [...contactOptionsEmail, ...populateUSers()];
      setBccOption(options);
    } else {
      setBccOption(contactOptionsEmail);
    }
  };

  const handleClose = () => {
    !isEditModal && dispatch(getJob({ jobData: {} }));
    onCloseAlert();
    onClose();
    dispatch(
      listClients({
        selected_client: [],
        selected_client_location: [],
        client_location_obj: {
          city: '',
          state: '',
          zip_code: '',
          country: '',
        },
      })
    );
    dispatch(getLead({ selected_contact: [] }));
    localStorage.removeItem('jobFormData');
  };

  return {
    handleClose,
    currentName,
    geo_location,
    setItemLocation,
    modalHeader,
    isOpenAlert,
    jobStatus,
    errMsg,
    errorList,
    onCloseAlert,
    tabIndex,
    tabList,
    isLoading:
      resGetJob.isLoading ||
      resCreateJob.isLoading ||
      resCreateQuick.isLoading ||
      resUpdateQuick.isLoading ||
      resUpdateJob.isLoading,
    isLoadingCompany: resCompany.isLoading,
    isLoadingGeo: resGeo.isLoading,
    setJobStatus,
    hasJobPostType,
    initialClientValues,
    setJobFormData,
    isDraft,
    jobFormData,
    aMOption,
    jobData,
    auth,
    onSubmit,
    initialValues,
    changeToDefault,
    setTabIndex,
    setHasJobPostType,
    defaultPitch,
    isEditModal,
    CompanyOption,
    CompanyChange,
    contactOption,
    locationOption,
    ccOption,
    onCcInputChange,
    bccOption,
    onBccInputchange,
    setIsDraft,
    initialJobDetailsValues,
    initialRecruitersValues,
    jobPairTemplate,
    jobPairing,
    onOpen,
    recOptions,
    setProtocol,
    protocol,
    handleProtocol,
    validationSchema,
    placeholderOptions,
    placeHolderClick,
    placholders,
    initialJobAdsValues,
    geoLocationChange,
    countryOption,
    itemLocation,
    setErrorList,
    setName,
    setSelectedCompanyName,
    jobSpecsOption,
    handleJobSpec,
  };
};
