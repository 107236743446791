import {
  Box,
  Checkbox,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link,
  Flex,
} from '@chakra-ui/react';
import LeadStatus from 'components/app/LeadStatus';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';

import { LeadsTypes } from 'types';
import toUrl from 'utils/toUrl';
import { useTableServices } from './useTableServices';
import EmailModal from 'components/app/Global/Email/Lead';
import FAIcon from 'components/lib/FAIcon';

export default function NewPropectsTable() {
  const {
    isCheck,
    isIndeterminate,
    isCheckAll,
    leads,
    handleCheck,
    handleClick,
    handleSelectAll,
    handleSort,
  } = useTableServices();
  const headers: { label: ReactElement | string }[] = [
    {
      label: (
        <Checkbox
          onChange={handleSelectAll}
          isIndeterminate={isIndeterminate}
          checked={isCheckAll}
          defaultChecked={false}
          colorScheme="purple"
        />
      ),
    },
    { label: 'Prospect Details' },
    { label: 'Client Name' },
    { label: 'Contact Information' },
    { label: 'Client Job Link' },
    { label: 'Status ' },
    {
      label: (
        <Flex gap="10px" justifyContent="center" alignItems="center">
          Created Date
          <Flex onClick={() => handleSort('created_at')} cursor="pointer">
            <FAIcon iconName="sort" />
          </Flex>
        </Flex>
      ),
    },
  ];

  const [emailModal, setEmailModal] = useState<{
    open: boolean;
    rowId: number | null;
  }>({ open: false, rowId: null });
  return (
    <>
      <Table>
        <Thead>
          <Tr bg="default.white.600">
            {headers.map((title, key: number) => (
              <Th key={`lead-th-${key}`}>
                <Box color="default.gray.600">{title.label}</Box>
              </Th>
            ))}
          </Tr>
        </Thead>

        <Tbody
          boxSizing="border-box"
          background="default.white.100"
          borderBottom="1px solid"
          borderColor="default.white.400"
        >
          {leads.map((lead: LeadsTypes, index: number) => (
            <Tr
              key={`lead-tr-${lead.id}`}
              _hover={{
                bg: '#f8f9fa',
              }}
            >
              <Td w={50} cursor="pointer">
                <Checkbox
                  onChange={handleCheck}
                  isChecked={isCheck.includes(lead.id)}
                  id={lead.id.toString()}
                  key={lead.id}
                  colorScheme="purple"
                />
              </Td>
              <Td
                width={250}
                onClick={() => handleClick(lead)}
                cursor="pointer"
              >
                <Box whiteSpace="pre-line" maxWidth="250px">
                  <Box
                    fontWeight="bold"
                    textTransform="capitalize"
                    style={{ textWrap: 'nowrap' }}
                  >
                    {[lead.first_name, lead.last_name].join(' ')}
                  </Box>
                  <Box fontSize="sm"> {lead.title}</Box>
                </Box>
              </Td>
              <Td width="50px" p="5px 24px" fontSize="14px" cursor="pointer">
                <Link
                  href={`/clients/my-clients/${lead.client?.id}/overview`}
                  target="_blank"
                >
                  {lead?.client?.name}
                </Link>
              </Td>

              <Td width={250} cursor="pointer">
                <Box>
                  <Link
                    fontWeight="bold"
                    fontSize="sm"
                    onClick={() => setEmailModal({ open: true, rowId: index })}
                  >
                    {lead.primary_email}
                  </Link>
                  <Box fontSize="sm"> {lead.personal_phone}</Box>
                </Box>
              </Td>
              <Td p="5px 24px" fontSize="14px" cursor="pointer">
                <Box
                  w={350}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  <Link href={toUrl(lead.client_job_link)} target="_blank">
                    {lead?.client_job_title || lead.client_job_link}
                  </Link>
                </Box>
              </Td>
              <Td onClick={() => handleClick(lead)} cursor="pointer">
                <LeadStatus status={lead.leadStatus.lead_status} />
              </Td>
              <Td
                width="50px"
                p="5px 24px"
                fontSize="14px"
                onClick={() => handleClick(lead)}
                cursor="pointer"
              >
                {moment.utc(lead?.created_at).format('MM/DD/YYYY')}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {emailModal.open ? (
        <EmailModal
          isOpen={emailModal.open}
          onClose={() => setEmailModal({ open: false, rowId: null })}
          email={leads[emailModal.rowId]?.primary_email || ''}
          id={leads[emailModal.rowId].id}
          allowedCategory={['Client', 'Contact', 'Signatures']}
        />
      ) : null}
    </>
  );
}
