import { TabPanels } from '@chakra-ui/react';

import ClientForm from 'components/app/Jobs/Modal/Job/Layout/TabItem/Client';
import JobDetailsForm from 'components/app/Jobs/Modal/Job/Layout/TabItem/JobDetails';
import { CustomizePitchQuickForm } from '../TabItem/CustomizePitch/Quick';
import JobAdsForm from 'components/app/Jobs/Modal/Job/Layout/TabItem/JobAds';
import RecruitingTeam from 'components/app/Jobs/Modal/Job/Layout/TabItem/RecruitingTeam';
import { CustomizePitchFullJobForm } from '../TabItem/CustomizePitch/FullJob';

interface JobsTabPanelProps {
  setJobStatus: any;
  hasJobPostType: any;
  initialClientValues: any;
  setJobFormData: any;
  isDraft: any;
  jobFormData: any;
  aMOption: any;
  jobData: any;
  auth: any;
  onSubmit: any;
  initialValues: any;
  changeToDefault: any;
  setTabIndex: any;
  setHasJobPostType: any;
  defaultPitch: any;
  isEditModal: any;
  CompanyOption: any;
  CompanyChange: any;
  contactOption: any;
  locationOption: any;
  ccOption: any;
  onCcInputChange: any;
  bccOption: any;
  onBccInputchange: any;
  setIsDraft: any;
  initialJobDetailsValues: any;
  initialRecruitersValues: any;
  jobPairTemplate: any;
  jobPairing: any;
  onOpen: any;
  recOptions: any;
  setProtocol: any;
  protocol: any;
  handleProtocol: any;
  validationSchema: any;
  placeholderOptions: any;
  placeHolderClick: any;
  placholders: any;
  initialJobAdsValues: any;
  geoLocationChange: any;
  countryOption: any;
  itemLocation: any;
  setErrorList: any;
  setName: any;
  isLoadingCompany: any;
  isLoadingGeo: any;
  tabList: any;
  tabIndex: any;
  searchableLeads: any;
  searchableClient: any;
  isOpenAlert: any;
  isLoading: any;
  modalHeader: any;
  jobId: any;
  jobStatus: any;
  onClose: () => void;
  setSelectedCompanyName: any;
  jobSpecsOption: any;
  handleJobSpec: any;
}

export const JobsTabPanel = ({
  setJobStatus,
  hasJobPostType,
  initialClientValues,
  setJobFormData,
  isDraft,
  jobFormData,
  aMOption,
  jobData,
  auth,
  onSubmit,
  initialValues,
  changeToDefault,
  setTabIndex,
  setHasJobPostType,
  defaultPitch,
  isEditModal,
  CompanyOption,
  CompanyChange,
  contactOption,
  locationOption,
  ccOption,
  onCcInputChange,
  bccOption,
  onBccInputchange,
  setIsDraft,
  initialJobDetailsValues,
  initialRecruitersValues,
  jobPairTemplate,
  jobPairing,
  onOpen,
  recOptions,
  setProtocol,
  protocol,
  handleProtocol,
  validationSchema,
  placeholderOptions,
  placeHolderClick,
  placholders,
  initialJobAdsValues,
  geoLocationChange,
  countryOption,
  itemLocation,
  setErrorList,
  setName,
  isLoadingCompany,
  isLoadingGeo,
  tabList,
  tabIndex,
  searchableLeads,
  searchableClient,
  isOpenAlert,
  isLoading,
  modalHeader,
  jobId,
  jobStatus,
  onClose,
  setSelectedCompanyName,
  jobSpecsOption,
  handleJobSpec,
}: JobsTabPanelProps) => {
  return (
    <TabPanels h="inherit">
      {tabIndex.id === 0 && (
        <ClientForm
          setJobStatus={setJobStatus}
          hasJobPostType={hasJobPostType}
          initialClientValues={initialClientValues}
          setJobFormData={setJobFormData}
          isDraft={isDraft}
          jobFormData={jobFormData}
          aMOption={aMOption}
          jobData={jobData}
          auth={auth}
          onSubmit={onSubmit}
          initialValues={initialValues}
          changeToDefault={changeToDefault}
          tabList={tabList}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          setHasJobPostType={setHasJobPostType}
          defaultPitch={defaultPitch}
          isEditModal={isEditModal}
          searchableLeads={searchableLeads}
          searchableClient={searchableClient}
          CompanyOption={CompanyOption}
          CompanyChange={CompanyChange}
          companyLoading={isLoadingCompany}
          contactOption={contactOption}
          locationOption={locationOption}
          ccOption={ccOption}
          onCcInputChange={onCcInputChange}
          bccOption={bccOption}
          onBccInputchange={onBccInputchange}
          isOpenAlert={isOpenAlert}
          setIsDraft={setIsDraft}
          onClose={onClose}
          setSelectedCompanyName={setSelectedCompanyName}
          jobSpecsOption={jobSpecsOption}
          handleJobSpec={handleJobSpec}
        />
      )}
      {tabIndex.id === 1 && (
        <JobDetailsForm
          initialJobDetailsValues={initialJobDetailsValues}
          jobFormData={jobFormData}
          onSubmit={(e) => onSubmit(e)}
          jobPairTemplate={jobPairTemplate}
          jobPairing={(e) => jobPairing(e)}
          isDraft={isDraft}
          setJobFormData={(e) => setJobFormData(e)}
          initialValues={initialValues}
          tabList={tabList}
          tabIndex={tabIndex}
          setTabIndex={(e) => setTabIndex(e)}
          isOpenAlert={isOpenAlert}
          loading={isLoading}
          setIsDraft={(e) => setIsDraft(e)}
          onOpen={onOpen}
          hasJobPostType={hasJobPostType}
          setJobStatus={(e) => setJobStatus(e)}
          aMOption={aMOption}
          recOptions={recOptions}
          isEditModal={isEditModal}
          setProtocol={(e) => setProtocol(e)}
          protocol={protocol}
          handleProtocol={(e) => handleProtocol(e)}
          onClose={onClose}
        />
      )}
      {tabIndex.id === 2 &&
        (hasJobPostType === '2' ? (
          <CustomizePitchQuickForm
            setJobFormData={setJobFormData}
            onSubmit={onSubmit}
            jobFormData={jobFormData}
            initialValues={initialValues}
            tabList={tabList}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            isOpenAlert={isOpenAlert}
            onOpen={onOpen}
            validationSchema={validationSchema}
            jobStatus={jobStatus}
            placeholderOptions={placeholderOptions}
            placeHolderClick={placeHolderClick}
            placholders={placholders}
            isLoading={isLoading}
            setErrorList={setErrorList}
            modalHeader={modalHeader}
            onClose={onClose}
          />
        ) : (
          <RecruitingTeam
            setJobFormData={setJobFormData}
            initialRecruitersValues={initialRecruitersValues}
            setJobStatus={setJobStatus}
            onSubmit={onSubmit}
            jobFormData={jobFormData}
            initialValues={initialValues}
            tabList={tabList}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            aMOption={aMOption}
            recOptions={recOptions}
            isOpenAlert={isOpenAlert}
            isEditModal={isEditModal}
            onOpen={onOpen}
            loading={isLoading}
            onClose={onClose}
            isDraft={isDraft}
            setIsDraft={(e) => setIsDraft(e)}
          />
        ))}
      {tabIndex.id === 3 && (
        <JobAdsForm
          initialJobAdsValues={initialJobAdsValues}
          jobId={jobId}
          jobFormData={jobFormData}
          jobData={jobData}
          setJobStatus={(e) => setJobStatus(e)}
          onSubmit={(e) => onSubmit(e)}
          jobPairTemplate={jobPairTemplate}
          jobPairing={(e) => jobPairing(e)}
          isDraft={isDraft}
          setJobFormData={(e) => setJobFormData(e)}
          initialValues={initialValues}
          changeToDefault={(e) => changeToDefault(e)}
          tabList={tabList}
          tabIndex={tabIndex}
          setTabIndex={(e) => setTabIndex(e)}
          setName={(e) => setName(e)}
          itemLocation={itemLocation}
          geoLocationChange={(e, name) => geoLocationChange(e, name)}
          geoloading={isLoadingGeo}
          countryOption={countryOption}
          isOpenAlert={isOpenAlert}
          loading={isLoading}
          isEditModal={isEditModal}
          setIsDraft={(e) => setIsDraft(e)}
          onOpen={onOpen}
          onClose={onClose}
          placholders={placholders}
        />
      )}
      {tabIndex.id === 4 && (
        <CustomizePitchFullJobForm
          setJobFormData={setJobFormData}
          onSubmit={onSubmit}
          jobFormData={jobFormData}
          initialValues={initialValues}
          tabList={tabList}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          isOpenAlert={isOpenAlert}
          onOpen={onOpen}
          validationSchema={validationSchema}
          jobStatus={jobStatus}
          placeholderOptions={placeholderOptions}
          placeHolderClick={placeHolderClick}
          placholders={placholders}
          isLoading={isLoading}
          setErrorList={setErrorList}
          modalHeader={modalHeader}
          onClose={onClose}
        />
      )}
    </TabPanels>
  );
};
