// React Hooks
import { useEffect, useState } from 'react';

// Chakra UI Components
import {
  Box,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

// Custom Components and Modals
import Pagination from 'Library/Pagination';
import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';

// Candidate-related Components
import CandidateTR from 'components/app/Candidates/Table/TR/JobCandidateTR';
import MenuItemComponent from 'components/app/Candidates/components/MenuItemComponent';
import CandidatesTagModal from 'components/app/Candidates/Modals/Tag';
import CandidatesDrawer from 'components/app/Candidates/Drawer';
import CandidatesInterviewModal from 'components/app/Candidates/Drawer/Interviews/modal';

// Action Modals
import ChangeStatusModal from '../Modal/ChangeStatus';
import AddToCampaignModal from '../Modal/AddToCampaign';
import SubmitToClientModal from '../Modal/SubmitToClient';
import RejectModal from '../Modal/Reject';
import HiredModal from '../Modal/HiredModal';
import EmailsModal from 'components/app/Global/Email/Modal';

// Loading Component
import TrLoading from 'components/app/TrLoading';

// Custom Hook for Table Services
import { useViewCandidatesTableServices } from './useViewCandidatesTableServices';

interface TableInterface {
  hasStatus?: boolean;
  reloadCandidate: (e: boolean) => void;
}

const JobsViewCandidatesTable = ({
  hasStatus = true,
  reloadCandidate,
}: TableInterface) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure();

  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();

  const {
    isOpen: isOpenAddTags,
    onOpen: onOpenAddTags,
    onClose: onCloseAddTags,
  } = useDisclosure();

  const {
    isOpen: isOpenAddToCampaign,
    onOpen: onOpenAddToCampaign,
    onClose: onCloseAddToCampaign,
  } = useDisclosure();

  const {
    isOpen: isOpenSendEmail,
    onOpen: onOpenSendEmail,
    onClose: onCloseSendEmail,
  } = useDisclosure();

  const {
    isOpen: isOpenScheduleInterview,
    onOpen: onOpenScheduleInterview,
    onClose: onCloseScheduleInterview,
  } = useDisclosure();

  const {
    isOpen: isOpenSubmitToClient,
    onOpen: onOpenSubmitToClient,
    onClose: onCloseSubmitToClient,
  } = useDisclosure();

  const {
    isOpen: isOpenHired,
    onOpen: onOpenHired,
    onClose: onCloseHired,
  } = useDisclosure();

  const {
    params,
    bulkEmail,
    bulkChangeStatus,
    bulkTags,
    bulkReject,
    bulkRemoveJob,
    bulkOffer,
    setIsBulkAction,
    candidates,
    isCheck,
    setCandidateIds,
    handleSelectAll,
    isIndeterminate,
    isCheckAll,
    isLoading,
    handleCheck,
    handleClick,
    addTagClick,
    editCandidate,
    handleDeleteCandidate,
    handleHideCandidate,
    onStatusChange,
    moveToScreen,
    submitToClient,
    scheduleForInterview,
    handleSendEmail,
    changeStatus,
    rejectClick,
    moveToOffer,
    moveToHired,
    unRejectClick,
    removeJobClick,
    hasInterview,
    candidatePage,
    pageChange,
    entryChange,
    filter,
    assocId,
    assocIdList,
    mainStatus,
    subStatus,
    statusChangeSuccess,
    isBulkAction,
    candidateData,
    candidateSingleData,
    jobId,
    tagSuccess,
    candidateIds,
    sendEmailclose,
    email,
    candidateId,
    emailList,
    interviewClose,
    intSubj,
    contactOptionValue,
    submitClientClose,
    drawerOnClose,
    rowId,
    setRowId,
    candidateCount,
    handlePrevData,
    handleNextData,
    handleSort,
    bulkHide,
    bulkUnreject,
    bulkScreen,
    rejectCallBack,
    callBackAction,
  } = useViewCandidatesTableServices({
    reloadCandidate,
    isOpenScheduleInterview,
    onOpenAddTags,
    onOpenReject,
    onOpenSubmitToClient,
    onOpenChangeStatus,
    isOpenAddTags,
    isOpenChangeStatus,
    isOpenReject,
    isOpenSendEmail,
    onOpenSendEmail,
    onCloseSubmitToClient,
    onCloseScheduleInterview,
    onCloseSendEmail,
    onOpenScheduleInterview,
    onOpen,
    onOpenHired,
    onClose,
  });

  const tableHeader: { label: any }[] = [
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Contact</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
            textAlign: 'center',
          }}
        >
          <Box>Job Candidate Status</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            textAlign: 'center',
            color: 'default.gray.600',
          }}
        >
          <Box>Location / Distance</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Resume Update On
            <Flex
              onClick={() => handleSort('resume_update_on')}
              cursor="pointer"
            >
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Last Activity
            <Flex onClick={() => handleSort('last_activity')} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Associated Jobs</Box>
        </Th>
      ),
    },
  ];

  const tableHeaderBlasted: { label: any }[] = [
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Contact</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            textAlign: 'center',
            color: 'default.gray.600',
          }}
        >
          <Box>Location / Distance</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
            textAlign: 'center',
          }}
        >
          <Box>Work Status</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Resume Update On
            <Flex
              onClick={() => handleSort('resume_update_on')}
              cursor="pointer"
            >
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Last Activity
            <Flex onClick={() => handleSort('last_activity')} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Associated Jobs</Box>
        </Th>
      ),
    },
  ];

  const tableHeaderScreen: { label: any }[] = [
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Contact</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
            textAlign: 'center',
          }}
        >
          <Box>Job Candidate Status</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            textAlign: 'center',
            color: 'default.gray.600',
          }}
        >
          <Box>Location / Distance</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
            textAlign: 'center',
          }}
        >
          <Box>Work Status</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Resume Update On
            <Flex
              onClick={() => handleSort('resume_update_on')}
              cursor="pointer"
            >
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Last Activity
            <Flex onClick={() => handleSort('last_activity')} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
  ];

  const tableHeaderSubmits: { label: any }[] = [
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Contact</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Submited On/Age</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            textAlign: 'center',
            color: 'default.gray.600',
          }}
        >
          <Box>Location / Distance</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
            textAlign: 'center',
          }}
        >
          <Box>Work Status</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Resume Update On
            <Flex
              onClick={() => handleSort('resume_update_on')}
              cursor="pointer"
            >
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Last Activity
            <Flex onClick={() => handleSort('last_activity')} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
  ];

  const tableHeaderInterviews: { label: any }[] = [
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Contact</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Interview Status</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
            textAlign: 'center',
          }}
        >
          <Box>Interview Date</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
            textAlign: 'center',
          }}
        >
          <Box>Location</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Resume Update On
            <Flex
              onClick={() => handleSort('resume_update_on')}
              cursor="pointer"
            >
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
            textAlign: 'center',
          }}
        >
          <Box>Work Status</Box>
        </Th>
      ),
    },
  ];

  const tableHeaderOffers: { label: any }[] = [
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Contact</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
            textAlign: 'center',
          }}
        >
          <Box>Offered On/Age</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            textAlign: 'center',
            color: 'default.gray.600',
          }}
        >
          <Box>Location / Distance</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
            textAlign: 'center',
          }}
        >
          <Box>Work Status</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Resume Update On
            <Flex
              onClick={() => handleSort('resume_update_on')}
              cursor="pointer"
            >
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Last Activity
            <Flex onClick={() => handleSort('last_activity')} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
  ];

  const tableHeaderHired: { label: any }[] = [
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Contact</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Candidate Start Date</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Placement Date</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Offered On/Age</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            textAlign: 'center',
            color: 'default.gray.600',
          }}
        >
          <Box>Location / Distance</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
            textAlign: 'center',
          }}
        >
          <Box>Work Status</Box>
        </Th>
      ),
    },
  ];

  const tableHeaderRejected: { label: any }[] = [
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Contact</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            textAlign: 'center',
            color: 'default.gray.600',
          }}
        >
          <Box>Location / Distance</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
            textAlign: 'center',
          }}
        >
          <Box>Work Status</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Resume Update On
            <Flex
              onClick={() => handleSort('resume_update_on')}
              cursor="pointer"
            >
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Last Activity
            <Flex onClick={() => handleSort('last_activity')} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Associated Jobs</Box>
        </Th>
      ),
    },
  ];

  const [CandidateTableHeader, setCandidateTableHeader] = useState(tableHeader);

  useEffect(() => {
    switch (params.jobsTabNav) {
      case 'blasted':
        setCandidateTableHeader(tableHeaderBlasted);
        break;
      case 'screen':
        setCandidateTableHeader(tableHeaderScreen);
        break;
      case 'submits':
        setCandidateTableHeader(tableHeaderSubmits);
        break;
      case 'interviews':
        setCandidateTableHeader(tableHeaderInterviews);
        break;
      case 'offers':
        setCandidateTableHeader(tableHeaderOffers);
        break;
      case 'hired':
        setCandidateTableHeader(tableHeaderHired);
        break;
      case 'rejected':
        setCandidateTableHeader(tableHeaderRejected);
        break;
      default:
        setCandidateTableHeader(tableHeader);
        break;
    }
  }, [params.jobsTabNav]);

  const CandidateButtonItem = () => {
    if (params.jobsTabId === 'in-progress') {
      if (params.jobsTabNav === 'submits') {
        return (
          <>
            <Button
              variant="basic"
              leftIcon="envelope"
              size="sm"
              onClick={bulkEmail}
            >
              Send Email
            </Button>
            <Button
              variant="basic"
              leftIcon="person-circle-check"
              size="sm"
              onClick={bulkChangeStatus}
            >
              Change Status
            </Button>
            <Button
              variant="basic"
              leftIcon="tags"
              size="sm"
              onClick={bulkTags}
            >
              Add Tags
            </Button>
            <Button
              variant="basic"
              leftIcon="circle-xmark"
              size="sm"
              onClick={bulkReject}
            >
              Reject
            </Button>
            <Button
              variant="basic"
              leftIcon="ban"
              size="sm"
              onClick={bulkRemoveJob}
            >
              Remove from Job
            </Button>
          </>
        );
      }
      if (params.jobsTabNav === 'interviews') {
        return (
          <>
            <Button
              variant="basic"
              leftIcon="file"
              size="sm"
              onClick={bulkOffer}
            >
              Move to Offer
            </Button>
            <Button
              variant="basic"
              leftIcon="envelope"
              size="sm"
              onClick={bulkEmail}
            >
              Send Email
            </Button>
            <Button
              variant="basic"
              leftIcon="person-circle-check"
              size="sm"
              onClick={bulkChangeStatus}
            >
              Change Status
            </Button>
            <Button
              variant="basic"
              leftIcon="tags"
              size="sm"
              onClick={bulkTags}
            >
              Add Tags
            </Button>
            <Button
              variant="basic"
              leftIcon="circle-xmark"
              size="sm"
              onClick={bulkReject}
            >
              Reject
            </Button>
            <Button
              variant="basic"
              leftIcon="ban"
              size="sm"
              onClick={bulkRemoveJob}
            >
              Remove from Job
            </Button>
          </>
        );
      }
      if (params.jobsTabNav === 'offers') {
        return (
          <>
            <Button
              variant="basic"
              leftIcon="envelope"
              size="sm"
              onClick={bulkEmail}
            >
              Send Email
            </Button>
            <Button
              variant="basic"
              leftIcon="person-circle-check"
              size="sm"
              onClick={bulkChangeStatus}
            >
              Change Status
            </Button>
            <Button
              variant="basic"
              leftIcon="tags"
              size="sm"
              onClick={bulkTags}
            >
              Add Tags
            </Button>
            <Button
              variant="basic"
              leftIcon="circle-xmark"
              size="sm"
              onClick={bulkReject}
            >
              Reject
            </Button>
            <Button
              variant="basic"
              leftIcon="ban"
              size="sm"
              onClick={bulkRemoveJob}
            >
              Remove from Job
            </Button>
          </>
        );
      }
      if (
        params.jobsTabNav === 'screen' ||
        params.jobsTabNav === 'blasted' ||
        !params.jobsTabNav
      ) {
        return (
          <>
            <Button
              variant="basic"
              leftIcon="plus"
              size="sm"
              onClick={() => {
                onOpenAddToCampaign();
                setIsBulkAction(true);
                const filteredCandidates = candidates
                  .filter((candidate: any) => isCheck.includes(candidate.id))
                  .map((candidate: any) => candidate.associate_id);
                setCandidateIds(filteredCandidates);
              }}
            >
              Add to Campaign
            </Button>

            <Button
              variant="basic"
              leftIcon="envelope"
              size="sm"
              onClick={bulkEmail}
            >
              Send Email
            </Button>
            <Button
              variant="basic"
              leftIcon="person-circle-check"
              size="sm"
              onClick={bulkChangeStatus}
            >
              Change Status
            </Button>
            <Button
              variant="basic"
              leftIcon="tags"
              size="sm"
              onClick={bulkTags}
            >
              Add Tags
            </Button>
            <Button
              variant="basic"
              leftIcon="eye-slash"
              size="sm"
              onClick={bulkHide}
            >
              Hide
            </Button>
            <Button
              variant="basic"
              leftIcon="circle-xmark"
              size="sm"
              onClick={bulkReject}
            >
              Reject
            </Button>
            <Button
              variant="basic"
              leftIcon="ban"
              size="sm"
              onClick={bulkRemoveJob}
            >
              Remove from Job
            </Button>
          </>
        );
      }
      if (params.jobsTabNav == 'hired') {
        return (
          <>
            <Button
              variant="basic"
              leftIcon="envelope"
              size="sm"
              onClick={bulkEmail}
            >
              Send Email
            </Button>
            <Button
              variant="basic"
              leftIcon="person-circle-check"
              size="sm"
              onClick={bulkChangeStatus}
            >
              Change Status
            </Button>
            <Button
              variant="basic"
              leftIcon="tags"
              size="sm"
              onClick={bulkTags}
            >
              Add Tags
            </Button>
            <Button
              variant="basic"
              leftIcon="circle-xmark"
              size="sm"
              onClick={bulkReject}
            >
              Reject
            </Button>
            <Button
              variant="basic"
              leftIcon="ban"
              size="sm"
              onClick={bulkRemoveJob}
            >
              Remove from Job
            </Button>
          </>
        );
      }
      if (params.jobsTabNav === 'rejected') {
        return (
          <>
            <Button
              variant="basic"
              leftIcon="person-circle-check"
              size="sm"
              onClick={bulkChangeStatus}
            >
              Change Status
            </Button>
            <Button
              variant="basic"
              leftIcon="envelope"
              size="sm"
              onClick={bulkEmail}
            >
              Send Email
            </Button>
            <Button
              variant="basic"
              leftIcon="tags"
              size="sm"
              onClick={bulkTags}
            >
              Add Tags
            </Button>
            <Button
              variant="basic"
              leftIcon="rotate-left"
              size="sm"
              onClick={bulkUnreject}
            >
              Un - Reject
            </Button>
            <Button
              variant="basic"
              leftIcon="ban"
              size="sm"
              onClick={bulkRemoveJob}
            >
              Remove from Job
            </Button>
          </>
        );
      }
    } else if (params.jobsTabId === 'search') {
      return (
        <>
          <Button
            variant="basic"
            leftIcon="plus"
            size="sm"
            onClick={bulkScreen}
          >
            Add to Screen
          </Button>
          <Button variant="basic" leftIcon="tags" size="sm" onClick={bulkTags}>
            Add Tags
          </Button>
          <Button
            variant="basic"
            leftIcon="eye-slash"
            size="sm"
            onClick={bulkHide}
          >
            Hide
          </Button>
        </>
      );
    }

    return <></>;
  };

  return (
    <>
      {isCheck.length > 0 && (
        <Flex pb={6} gap={4}>
          <Tag
            display="flex"
            borderRadius="4px"
            variant="subtle"
            color="#545454"
            bg="#FAF7FF"
            fontWeight="regular"
            fontSize="md"
            border="1px solid #AE8FE2"
            height="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap={2} fontSize="14px" alignItems="center">
              <Box color="#6930CA" fontSize="sm">
                {isCheck.length}
              </Box>{' '}
              {isCheck.length > 1 ? 'items' : 'item'} selected
            </Flex>
          </Tag>
          <CandidateButtonItem />
        </Flex>
      )}
      <TableContainer
        boxSizing="border-box"
        border="1px solid #EEEEEE"
        borderRadius="4px"
        height="inherit"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              <Th
                sx={{
                  position: 'sticky',
                  left: 0,
                  bg: 'default.white.600',
                  color: 'default.gray.600',
                  boxShadow: 'inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)',
                }}
                key={`table-candidate-header-interview-1`}
              >
                <Flex gap="30px">
                  <Checkbox
                    onChange={(e: any) => {
                      handleSelectAll(e);
                    }}
                    isIndeterminate={isIndeterminate}
                    isChecked={isCheckAll}
                    defaultChecked={false}
                    colorScheme="purple"
                  />
                  <Box>Candidate Details</Box>
                </Flex>
              </Th>
              {(!hasStatus
                ? CandidateTableHeader.filter((item, key: number) => key !== 1)
                : CandidateTableHeader
              ).map((title, key: number) => {
                return title.label;
              })}
            </Tr>
          </Thead>

          {isLoading ? (
            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              <TrLoading rows={3} columns={CandidateTableHeader.length + 1} />
            </Tbody>
          ) : (
            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {candidates.filter(Boolean).map((candidate: any, key: number) => {
                return (
                  <CandidateTR
                    key={`${candidate.id}-${key}`}
                    candidate={candidate}
                    handleCheck={(e: any) => handleCheck(e)}
                    isCheck={isCheck}
                    handleClick={() => handleClick(candidate)}
                    associateToJob={() => {}}
                    addTagClick={() => addTagClick(candidate.id)}
                    editCandidate={() => editCandidate(candidate)}
                    handleDeleteCandidate={(candidate: any) =>
                      handleDeleteCandidate(candidate)
                    }
                    handleHideCandidate={(candidate: any) =>
                      handleHideCandidate(candidate)
                    }
                    fromJob={true}
                    statusChangeSuccessCb={(e) => onStatusChange(e)}
                    hasStatus={hasStatus}
                    HandleMenuItem={
                      <MenuItemComponent
                        key={`menu-candidate-${candidate.id}`}
                        id={candidate.id}
                        type={params.jobsTabId}
                        nav={params.jobsTabNav}
                        openTags={() => addTagClick(candidate.id)}
                        addToScreen={() => moveToScreen(candidate)}
                        addToCapaign={() => {
                          onOpenAddToCampaign();
                          setIsBulkAction(false);
                          setCandidateIds([candidate.associate_id]);
                        }}
                        submitToClients={() => submitToClient(candidate)}
                        scheduleInterview={() => {
                          scheduleForInterview(candidate);
                        }}
                        sendEmail={() => handleSendEmail(candidate)}
                        changeStatus={() => changeStatus(candidate)}
                        hide={() => handleHideCandidate(candidate)}
                        del={() => handleDeleteCandidate(candidate)}
                        reject={() => rejectClick(candidate)}
                        offer={() => moveToOffer(candidate)}
                        moveToHired={() => moveToHired(candidate)}
                        unReject={() => unRejectClick(candidate)}
                        removeFromJob={() => removeJobClick(candidate)}
                      />
                    }
                    hasInterview={hasInterview}
                    jobID={Number(params.jobsId)}
                  />
                );
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>

      <Pagination
        totalPages={candidatePage.lastPage}
        currentPage={candidatePage.currentPage}
        onPageChange={(page) => pageChange(page)}
        onEntryChange={(entries) => entryChange(entries)}
        totalEntries={candidatePage.count}
        currentCount={candidates?.length}
        targetCount={filter.take}
      />

      {isOpenChangeStatus && (
        <ChangeStatusModal
          isOpen={isOpenChangeStatus}
          onClose={onCloseChangeStatus}
          id={assocId}
          idList={assocIdList}
          main_status={mainStatus}
          sub_status={subStatus}
          onSuccess={() => statusChangeSuccess()}
          isBulkChangeStatus={isBulkAction}
          candidate={
            Object.keys(candidateData).length > 0
              ? candidateData
              : candidateSingleData
          }
        />
      )}

      {isOpenReject && (
        <RejectModal
          id={assocId}
          idList={assocIdList}
          isOpen={isOpenReject}
          onClose={onCloseReject}
          isBulkReject={isBulkAction}
          callback={rejectCallBack}
        />
      )}

      {isOpenHired && (
        <HiredModal
          isOpen={isOpenHired}
          onClose={onCloseHired}
          candidate={candidateData}
          onCallback={callBackAction}
          jobId={Number(params.jobsId)}
        />
      )}

      {isOpenAddTags && (
        <CandidatesTagModal
          id={candidateId}
          isOpen={isOpenAddTags}
          onClose={onCloseAddTags}
          idList={isCheck}
          isBulkTag={isBulkAction}
          onSuccess={() => tagSuccess()}
        />
      )}
      {isOpenAddToCampaign && (
        <AddToCampaignModal
          id={candidateIds}
          isOpen={isOpenAddToCampaign}
          onClose={onCloseAddToCampaign}
          isBulk={isBulkAction}
        />
      )}
      {isOpenSendEmail && (
        <EmailsModal
          isOpen={isOpenSendEmail}
          onClose={sendEmailclose}
          email={email}
          id={candidateId}
          candidate_id={candidateId}
          job_id={Number(params.jobsId)}
          allowedCategory={['Candidates', 'Jobs']}
          idList={isCheck}
          emailList={emailList}
          isBulkEmail={isBulkAction}
        />
      )}
      {isOpenScheduleInterview && (
        <CandidatesInterviewModal
          isOpen={isOpenScheduleInterview}
          onClose={interviewClose}
          associate={assocId}
          subject={intSubj}
          isJobView={true}
          contactOptionValue={contactOptionValue}
          isEdit={false}
        />
      )}
      {isOpenSubmitToClient && (
        <SubmitToClientModal
          isOpen={isOpenSubmitToClient}
          onClose={submitClientClose}
          candidate={candidateData}
          job_id={Number(params.jobsId)}
          callback={() => {}}
        />  
      )}
      {isOpen && (
        <CandidatesDrawer
          isOpen={isOpen}
          onClose={drawerOnClose}
          rowId={rowId}
          setRowId={setRowId}
          totalCandidates={candidateCount}
          handlePrevData={handlePrevData}
          handleNextData={handleNextData}
          isLoading={isLoading}
          candidates={candidates}
        />
      )}
    </>
  );
};

export default JobsViewCandidatesTable;
