import {
  Box,
  Checkbox,
  CheckboxGroup,
  Collapse,
  Flex,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Icon,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import EmailsModal from 'components/app/Global/Email/Modal';
import LeadStatus from 'components/app/LeadStatus';
import FAIcon from 'components/lib/FAIcon';
import { ChevronDown, ChevronRight } from 'lucide-react';
import moment from 'moment';
import React, {
  ReactElement,
  ReactEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { navClient } from 'store/client.slice';
import { getLead } from 'store/leads.slice';
import toUrl from 'utils/toUrl';
import { LeadsDrawer } from '../Drawer';
import { useTableServices } from './useTableServices';

export default function ExistingClientProspects() {
  const { handleClick, handleSelectAll, handleSort } = useTableServices();
  const totalLeads = useSelector((state: any) => {
    const leads = state.leads.repeatProspects;
    let total = 0;

    leads.forEach((lead: any) => {
      total += lead.leads.length;
    });
    return total;
  });
  const [checkAll, setCheckAll] = useState(false);
  const [checked, setChecked] = useState([]);
  const headers: { label: ReactElement | string; width?: number }[] = [
    {
      label: (
        <Checkbox
          onChange={handleAllChecked}
          isIndeterminate={checked.length && checked.length !== totalLeads}
          checked={checkAll}
          defaultChecked={false}
          colorScheme="purple"
        />
      ),
    },
    { label: 'Prospect Details' },
    { label: 'Contact Information' },
    { label: 'Client Job Link' },
    { label: 'Status ' },
    {
      label: (
        <Flex gap="10px" justifyContent="center" alignItems="center">
          Created Date
          <Flex onClick={() => handleSort('created_at')} cursor="pointer">
            <FAIcon iconName="sort" />
          </Flex>
        </Flex>
      ),
    },
  ];
  const leads = useSelector((state: any) => state.leads.repeatProspects);

  function handleCheck(checks: any) {
    const checkIds: any = [];
    const uncheckedId: any = [];
    checks.forEach((check: any) => {
      if (check.checked) {
        checkIds.push(check.id);
      } else {
        uncheckedId.push(check.id);
      }
    });

    setChecked((state) => {
      const newArr = [...state, ...checkIds];

      return newArr.filter((id) => !uncheckedId.includes(id));
    });
  }

  function handleAllChecked(e: any) {
    const { checked } = e.target;
    if (checked) {
      const arr: any = [];
      leads.forEach((client: any) => {
        client.leads.forEach((lead: any) => {
          arr.push(lead.id);
        });
      });
      setChecked([...arr]);
    } else {
      setChecked([]);
    }
    setCheckAll(checked);
  }

  return (
    <Table>
      <Thead>
        {' '}
        <Tr bg="default.white.600">
          {headers.map((title, key: number) => (
            <Th key={`lead-th-${key}`}>
              <Box color="default.gray.600">{title.label}</Box>
            </Th>
          ))}
        </Tr>
      </Thead>

      <Tbody
        boxSizing="border-box"
        background="default.white.100"
        borderBottom="1px solid"
        borderColor="default.white.400"
      >
        {leads.map((client: any) => (
          <TableRow
            client={client}
            handleClick={handleClick}
            checked={checked}
            handleCheck={handleCheck}
          />
        ))}
      </Tbody>
    </Table>
  );
}

function TableRow({
  client,
  handleCheck,
  checked,
}: {
  client: { client_name: string; leads: any[]; client_id: number };
  handleClick: (lead: any) => void;
  handleCheck: (check: { id: number; checked: boolean }[]) => void;
  checked: any;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { clientNav, clientTab } = useSelector((state: any) => state.clients);
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isOpenDrawer,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const [rowId, setRowId] = useState<number | null>(null);
  const [emailModal, setEmailModal] = useState<{
    open: boolean;
    rowId: number | null;
  }>({ open: false, rowId: null });
  const leadsIds = useMemo(() => {
    return client.leads.map((lead) => lead.id);
  }, [client]);
  const leadsChecked = useMemo(() => {
    return leadsIds.filter((id) => checked.includes(id));
  }, [checked, leadsIds]);

  function handleAllChecked(e: any) {
    const { checked } = e.target;
    const checks = leadsIds.map((id: number) => ({ id: id, checked: checked }));
    handleCheck(checks);
  }
  function handleClick(lead: any, rowId: number) {
    setRowId(rowId + 1);
    navigate(`/leads/${lead.id}/details`);
    onDrawerOpen();
  }
  const handlePrevData = () => {
    const leadID = client.leads[rowId - 1 - 1].id;
    setRowId((prevKey: number) => prevKey - 1);
    navigate(`/leads/${leadID}/${params.leadsTab}`);
  };

  const handleNextData = () => {
    const leadID = client.leads[rowId - 1 + 1].id;
    setRowId((prevKey: number) => prevKey + 1);
    navigate(`/leads/${leadID}/${params.leadsTab}`);
  };
  return (
    <>
      <Tr
        sx={{
          bg: '#FAF5FF',
          color: 'primary.600',
          fontSize: '12px',
          fontWeight: 700,
          textTransform: 'uppercase',
          cursor: 'pointer',
          zIndex: 0,
        }}
        _hover={{
          bg: '#f8f9fa',
          zIndex: 0,
        }}
      >
        <Td w={50} cursor="pointer" py={'8px'}>
          <Checkbox
            isIndeterminate={
              leadsChecked.length && leadsChecked.length !== client.leads.length
            }
            isChecked={
              leadsChecked.length && leadsChecked.length === client.leads.length
            }
            defaultChecked={false}
            colorScheme="purple"
            onChange={handleAllChecked}
          />
        </Td>
        <Td
          colSpan={4}
          alignItems="flex-start"
          color={'#6930CA'}
          py="8px"
          sx={{ cursor: 'pointer' }}
          onClick={onToggle}
        >
          <Flex alignItems={'center'} gap="8px" w="max-content">
            <Box width="16px">
              {isOpen ? (
                <Icon as={BsChevronDown} />
              ) : (
                <Icon as={BsChevronRight} />
              )}
            </Box>

            <Link
              fontSize="12px"
              href={`/clients/my-clients/${client.client_id}/overview`}
              target="_blank"
              rel="noreferrer"
            >
              {client.client_name}
            </Link>
          </Flex>
        </Td>
        <Td colSpan={1}></Td>
      </Tr>

      {isOpen && (
        <>
          {client.leads.map((lead, index) => (
            <Tr
              key={`${lead.id}--${index}`}
              _hover={{
                bg: '#f8f9fa',
              }}
            >
              <Td cursor="pointer">
                <Checkbox
                  isChecked={leadsChecked.includes(lead.id)}
                  defaultChecked={false}
                  colorScheme="purple"
                  onChange={(e) =>
                    handleCheck([{ id: lead.id, checked: e.target.checked }])
                  }
                />
              </Td>
              <Td
                onClick={() => handleClick(lead, index)}
                cursor="pointer"
                py={'20px'}
              >
                <Flex direction="column" width={250}>
                  <Text
                    fontWeight="bold"
                    textTransform="capitalize"
                    style={{ textWrap: 'nowrap' }}
                    isTruncated
                  >
                    {[lead.first_name, lead.last_name].join(' ')}
                  </Text>
                  <Box
                    fontSize="14px"
                    color="default.secondarytext"
                    isTruncated
                  >
                    {' '}
                    {lead.title}
                  </Box>
                </Flex>
              </Td>

              <Td cursor="pointer">
                <Flex
                  fontSize="14px"
                  color="default.primarytext"
                  sx={{ textWrap: 'balance' }}
                >
                  <Link
                    fontWeight="bold"
                    style={{ textWrap: 'nowrap' }}
                    onClick={() => setEmailModal({ open: true, rowId: index })}
                  >
                    {lead.primary_email}
                  </Link>
                  <Box>
                    <Link href={`tel:${lead.personal_phone}`}>
                      {lead.personal_phone}
                    </Link>
                  </Box>
                </Flex>
              </Td>
              <Td cursor="pointer" p="5px 24px">
                <Box w={350} isTruncated fontSize={'14px'}>
                  <Link href={toUrl(lead.client_job_link)} target="_blank">
                    {lead?.client_job_title || lead.client_job_link}
                  </Link>
                </Box>
              </Td>
              <Td onClick={() => handleClick(lead, index)} cursor="pointer">
                <LeadStatus status={lead.leadStatus.lead_status} />
              </Td>
              <Td
                fontSize="14px"
                onClick={() => handleClick(lead, index)}
                cursor="pointer"
                p="5px 24px"
              >
                {moment.utc(lead?.created_at).format('MM/DD/YYYY')}
              </Td>
            </Tr>
          ))}
        </>
      )}
      {emailModal.open ? (
        <EmailsModal
          isOpen={emailModal.open}
          onClose={() => setEmailModal({ open: false, rowId: null })}
          email={client.leads[emailModal.rowId]?.primary_email || ''}
          id={client.leads[emailModal.rowId].id}
          allowedCategory={['Client', 'Contact', 'Signatures']}
        />
      ) : null}
      {isOpenDrawer && (
        <LeadsDrawer
          isOpen={isOpenDrawer}
          onClose={() => {
            onDrawerClose();
            if (clientNav === 'leads') {
              dispatch(navClient({ clientNav: clientTab }));
              navigate(-1);
            } else {
              navigate('/leads');
            }
            dispatch(getLead({ leadData: {} }));
          }}
          rowId={rowId}
          totalLeads={client?.leads?.length}
          handlePrevData={handlePrevData}
          handleNextData={handleNextData}
        />
      )}
    </>
  );
}
