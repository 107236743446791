import moment from 'moment';

import {
  Box,
  Flex,
  Tag,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  Table,
  MenuItem,
  useDisclosure,
  Link,
  Stack,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import Pagination from 'Library/Pagination';

import FAIcon from 'components/lib/FAIcon';
import LeadStatus from 'components/app/LeadStatus';

import { LeadsModal } from '../Modal';
import { LeadsDrawer } from '../Drawer';

import { LeadsConvertClientModal } from '../Modal/ConvertContact';

import { useTableServices } from './useTableServices';
import { LeadsTypes } from 'types';
import { LeadsSendForApprovalModal } from '../Modal/SendForApproval';
import toUrl from 'utils/toUrl';
import { ReactElement } from 'react';
import NewPropectsTable from './NewProspectsTable';
import ExistingClientProspects from './ExistingClientProspects';
import { useDispatch, useSelector } from 'react-redux';
import { getLead } from 'store/leads.slice';
import { navClient } from 'store/client.slice';
import { useNavigate } from 'react-router';

export const LeadsTable = () => {
  const {
    isCheck,
    isIndeterminate,
    isCheckAll,
    leads,
    handleCheck,
    handleClick,
    handleSelectAll,
    handleSort,
    converClientClick,
    handleInactive,
    cancelApprovalClick,
    sendForApproval,
    marAsActiveClick,
    isOpenDrawer,
    onCloseDrawer,
    rowId,
    handlePrevData,
    handleNextData,
    isConvertClientOpen,
    onConvertClientClose,
    isConvertLeadOpen,
    onConvertLeadClose,
    isSendApproval,
    setIsSendApproval,
    leadPage,
    leadPgBtn,
    isEditLeadOpen,
    onEditLeadClose,
    pageChange,
    entryChange,
    handleBulkInactive,
  } = useTableServices();

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientNav, clientTab } = useSelector((state: any) => state.clients);
  const tableHeader: { label: any }[] = [
    {
      label: (
        <Checkbox
          onChange={handleSelectAll}
          isIndeterminate={isIndeterminate}
          checked={isCheckAll}
          defaultChecked={false}
          colorScheme="purple"
        />
      ),
    },
    { label: 'Lead Details' },
    { label: 'Contact Information' },
    { label: 'Status' },
    {
      label: (
        <Box>
          <FAIcon iconName="location-pin" /> Open Jobs
        </Box>
      ),
    },
    {
      label: (
        <Box>
          <FAIcon iconName="clipboard" /> Submits
        </Box>
      ),
    },
    {
      label: (
        <Box>
          <FAIcon iconName="phone" /> Interviews
        </Box>
      ),
    },
    {
      label: (
        <Flex gap="10px" justifyContent="center" alignItems="center">
          Created On
          <Flex onClick={() => handleSort('created_at')} cursor="pointer">
            <FAIcon iconName="sort" />
          </Flex>
        </Flex>
      ),
    },
    { label: '' },
  ];

  const tableHeaderUnclaimed: { label: any }[] = [
    {
      label: (
        <Checkbox
          onChange={handleSelectAll}
          isIndeterminate={isIndeterminate}
          checked={isCheckAll}
          defaultChecked={false}
          colorScheme="purple"
        />
      ),
    },
    { label: 'Lead Details' },
    { label: 'Client Job Link' },
    {
      label: (
        <Flex gap="10px" justifyContent="center" alignItems="center">
          Created Date
          <Flex onClick={() => handleSort('created_at')} cursor="pointer">
            <FAIcon iconName="sort" />
          </Flex>
        </Flex>
      ),
    },
    { label: '' },
  ];

  const handleMenuList = (lead: any) => {
    const type = lead?.leadType?.type;
    const status = lead?.leadStatus?.lead_status;
    if (status === 'Rejected') {
      return (
        <>
          <MenuItem onClick={() => converClientClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>Request to Convert</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => handleInactive(lead)} color="caution.800">
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="ban" />
              <Box ml={12}>Mark as Inactive</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (status === 'Approval Pending') {
      return (
        <>
          <MenuItem onClick={() => cancelApprovalClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="times" />
              <Box ml={12}>Cancel Approval</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (status === 'Active') {
      return (
        <>
          <MenuItem onClick={() => {}}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>View Contact</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => converClientClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>Request to Convert</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => handleInactive(lead)} color="caution.800">
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="ban" />
              <Box ml={12}>Mark as Inactive</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (
      type === 'Lead' &&
      (status === 'Draft' || status === 'Rejected')
    ) {
      return (
        <>
          <MenuItem onClick={() => sendForApproval(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="check-circle" />
              <Box ml={12}>Send for Approval</Box>
            </Flex>
          </MenuItem>

          <MenuItem onClick={() => converClientClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>Request to Convert</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => handleInactive(lead)} color="caution.800">
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="ban" />
              <Box ml={12}>Mark as Inactive</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (type === 'Lead' && status === 'Active') {
      return (
        <>
          <MenuItem onClick={() => converClientClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>Request to Convert</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => handleInactive(lead)} color="caution.800">
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="ban" />
              <Box ml={12}>Mark as Inactive</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (type === 'Lead' && status === 'Approval Pending') {
      return (
        <>
          <MenuItem onClick={() => cancelApprovalClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="times" iconColor="caution.800" />
              <Box ml={12}>Cancel Approval</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (type === 'Lead' && status === 'Inactive') {
      return (
        <>
          <MenuItem onClick={() => marAsActiveClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="square-check" />
              <Box ml={12}>Mark as Active</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (type === 'Qualified' && status === 'Active') {
      return (
        <>
          <MenuItem onClick={() => converClientClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>Request to Convert</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => handleInactive(lead)} color="caution.800">
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="ban" />
              <Box ml={12}>Mark as Inactive</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (type === 'Qualified' && status === 'Approval Pending') {
      return (
        <>
          <MenuItem onClick={() => cancelApprovalClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="times" iconColor="caution.800" />
              <Box ml={12}>Cancel Approval</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (type === 'Qualified' && status === 'Rejected') {
      return (
        <>
          <MenuItem onClick={() => sendForApproval(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="check-circle" />
              <Box ml={12}>Send for Approval</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => converClientClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>Request to Convert</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => handleInactive(lead)} color="caution.800">
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="ban" />
              <Box ml={12}>Mark as Inactive</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (status === 'Inactive') {
      return (
        <MenuItem onClick={() => marAsActiveClick(lead)}>
          <Flex alignItems="center" justifyContent="space-between">
            <FAIcon iconName="square-check" />
            <Box ml={12}>Mark as Active</Box>
          </Flex>
        </MenuItem>
      );
    }
  };

  return (
    <Stack
      spacing={0}
      sx={{
        height: '100%',
        w: 'calc(100% - 300px)',
        bgColor: '#fff',
        borderRadius: '10px',
        p: '16px',
      }}
    >
      {!leadPgBtn.is_prospect ? (
        <Flex justifyContent="space-between" pb={'16px'}>
          <Flex gap={4}>
            {isCheck.length > 0 ? (
              <>
                {' '}
                <Tag
                  display="flex"
                  borderRadius="4px"
                  variant="subtle"
                  color="#545454"
                  bg="#FAF7FF"
                  fontWeight="regular"
                  fontSize="md"
                  border="1px solid #AE8FE2"
                  height="32px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Flex gap={2} fontSize="14px" alignItems="center">
                    <Box color="#6930CA" fontSize="sm">
                      {isCheck.length}
                    </Box>{' '}
                    {isCheck.length > 1 ? 'items' : 'item'} selected
                  </Flex>
                </Tag>
                <Button
                  variant="basic"
                  leftIcon="ban"
                  size="sm"
                  onClick={handleBulkInactive}
                >
                  Mark as Inactive
                </Button>
              </>
            ) : (
              <Box mb={6}></Box>
            )}
          </Flex>

          <Button leftIcon="plus" variant="solid" onClick={onOpenModal}>
            Add New lead
          </Button>
        </Flex>
      ) : null}

      {leadPgBtn.is_prospect ? (
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          height="inherit"
          sx={{ overflowY: 'scroll' }}
        >
          {leadPgBtn.is_repeat_prospect ? (
            <ExistingClientProspects />
          ) : (
            <NewPropectsTable />
          )}
        </TableContainer>
      ) : (
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          height="inherit"
          sx={{ overflowY: 'scroll' }}
        >
          <Table>
            <Thead>
              <Tr bg="default.white.600">
                {(leadPgBtn.unclaimed ? tableHeaderUnclaimed : tableHeader).map(
                  (title, key: number) => (
                    <Th key={`lead-th-${key}`}>
                      <Box color="default.gray.600">{title.label}</Box>
                    </Th>
                  )
                )}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {leads.map((lead: LeadsTypes) => (
                <Tr
                  key={`lead-tr-${lead?.id}`}
                  _hover={{
                    bg: '#f8f9fa',
                  }}
                  cursor="pointer"
                >
                  <Td w={50}>
                    <Checkbox
                      onChange={handleCheck}
                      isChecked={isCheck.includes(lead?.id)}
                      id={lead?.id?.toString()}
                      key={lead?.id}
                      colorScheme="purple"
                    />
                  </Td>
                  <Td onClick={() => handleClick(lead)} width={250}>
                    <Box whiteSpace="pre-line" maxWidth="250px">
                      <Box
                        fontWeight="bold"
                        textTransform="capitalize"
                        style={{ textWrap: 'nowrap' }}
                      >
                        {[lead?.first_name, lead?.last_name].join(' ')}
                      </Box>
                      <Box fontSize="sm"> {lead?.title}</Box>
                      <Box fontSize="sm"> {lead?.client?.name}</Box>
                    </Box>
                  </Td>

                  {!leadPgBtn.unclaimed ? (
                    <>
                      <Td onClick={() => handleClick(lead)}>
                        <Box>
                          <Box fontWeight="bold" fontSize="sm">
                            {lead?.primary_email}
                          </Box>
                          <Box fontSize="sm"> {lead?.personal_phone}</Box>
                        </Box>
                      </Td>
                      <Td onClick={() => handleClick(lead)}>
                        <LeadStatus status={lead?.leadStatus?.lead_status} />
                      </Td>
                      <Td width="50px" p="5px 24px" fontSize="14px">
                        {lead?.total_opening}
                      </Td>
                      <Td width="50px" p="5px 24px" fontSize="14px">
                        {lead?.total_submits}
                      </Td>
                      <Td width="50px" p="5px 24px" fontSize="14px">
                        {lead?.total_interview}
                      </Td>
                    </>
                  ) : (
                    <Td width="50px" p="5px 24px">
                      {lead?.client_job_link !== '' && (
                        <Link
                          href={toUrl(lead?.client_job_link)}
                          target="_blank"
                          style={{ display: 'flex', gap: '7px' }}
                          rel="noreferrer"
                        >
                          {lead?.client_job_title ||
                            toUrl(lead?.client_job_link)}
                        </Link>
                      )}
                    </Td>
                  )}
                  <Td width="50px" p="5px 24px" fontSize="14px">
                    {moment.utc(lead?.created_at).format('MM/DD/YYYY hh:mm A')}
                  </Td>

                  {!leadPgBtn.is_prospect ? (
                    <Td>
                      <Menu>
                        <MenuButton>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>

                        <MenuList fontSize="sm">
                          {handleMenuList(lead)}
                        </MenuList>
                      </Menu>
                    </Td>
                  ) : null}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {isOpenDrawer && (
        <LeadsDrawer
          isOpen={isOpenDrawer}
          onClose={() => {
            onCloseDrawer();
            if (clientNav === 'leads') {
              dispatch(navClient({ clientNav: clientTab }));
              navigate(-1);
            } else {
              navigate('/leads');
            }
            dispatch(getLead({ leadData: {} }));
          }}
          rowId={rowId}
          totalLeads={leads?.length}
          handlePrevData={handlePrevData}
          handleNextData={handleNextData}
        />
      )}

      <Pagination
        totalPages={leadPage.lastPage}
        currentPage={leadPage.currentPage}
        onPageChange={(page) => pageChange(page)}
        totalEntries={leadPage.count}
        onEntryChange={(entries) => entryChange(entries)}
        currentCount={leads.length}
        targetCount={leadPgBtn.take}
      />

      {isConvertClientOpen && (
        <LeadsConvertClientModal
          isOpen={isConvertClientOpen}
          onClose={onConvertClientClose}
        />
      )}
      {isConvertLeadOpen && (
        <LeadsSendForApprovalModal
          isOpen={isConvertLeadOpen}
          onClose={onConvertLeadClose}
          isSendApproval={isSendApproval}
          setIsSendApproval={setIsSendApproval}
        />
      )}

      {isEditLeadOpen && (
        <LeadsModal
          isOpen={isEditLeadOpen}
          onClose={onEditLeadClose}
          modalTitle="Edit Lead"
        />
      )}

      {isOpenModal && (
        <LeadsModal
          isOpen={isOpenModal}
          onClose={onCloseModal}
          modalTitle="Create New Lead"
        />
      )}
    </Stack>
  );
};
