import { Box, Flex, Icon } from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import { IoCall } from 'react-icons/io5';
import { BiMailSend } from 'react-icons/bi';
import { BsFillChatSquareDotsFill } from 'react-icons/bs';
import { FaEnvelopeOpen } from 'react-icons/fa';
import office from '../../../assets/images/icons/office.svg';
import linkedIn from '../../../assets/images/icons/linkedin.svg';

export const tableHeader: { label: any; col: number; row: number; sub: any }[] =
  [
    { label: '', col: 1, row: 2, sub: [] },
    {
      label: (
        <Box textAlign="center" width="100%">
          {/* Renamed from New Leads to Leads to reduce confusion. The key name remains new_leads_talently/repeats for this. */}
          Leads
        </Box>
      ),
      col: 2,
      row: 1,
      sub: [
        {
          label: <Flex gap="4px">Talently</Flex>,
        },
        {
          label: <Flex gap="4px">Repeat</Flex>,
        },
      ],
    },
    {
      label: (
        <Box textAlign="center" width="100%">
          Leads w/ interview
        </Box>
      ),
      col: 1,
      row: 2,
      sub: [],
    },
    {
      label: (
        <Box textAlign="center" width="100%">
          Jobs
        </Box>
      ),
      col: 2,
      row: 1,
      sub: [
        {
          label: <Flex gap="4px">New</Flex>,
        },
        {
          label: <Flex gap="4px">Total</Flex>,
        },
      ],
    },
    {
      label: <Flex gap="4px">submits</Flex>,
      col: 1,
      row: 2,
      sub: [],
    },
    {
      label: (
        <Box textAlign="center" width="100%">
          Interviews
        </Box>
      ),
      col: 2,
      row: 1,
      sub: [
        {
          label: <Flex gap="4px">1st</Flex>,
        },
        {
          label: <Flex gap="4px">Total</Flex>,
        },
      ],
    },
    {
      label: <Flex gap="4px">offers</Flex>,
      col: 1,
      row: 2,
      sub: [],
    },
    {
      label: <Flex gap="4px">placements</Flex>,
      col: 1,
      row: 2,
      sub: [],
    },

    {
      label: <Flex gap="4px">revenue</Flex>,
      col: 1,
      row: 2,
      sub: [],
    },
    {
      label: (
        <Flex gap="4px">
          <Flex fontSize="15px" mt="auto" mb="auto" verticalAlign="center">
            <Icon as={IoCall} color="primary.500" />
          </Flex>
        </Flex>
      ),
      col: 1,
      row: 2,
      sub: [],
    },
    {
      label: (
        <Flex gap="4px">
          <Flex fontSize="15px" mt="auto" mb="auto" verticalAlign="center">
            <img
              src={office}
              style={{ width: '15px', height: '15px', objectFit: 'contain' }}
            />
          </Flex>
        </Flex>
      ),
      col: 1,
      row: 2,
      sub: [],
    },
    {
      label: (
        <Flex gap="4px">
          <Flex fontSize="15px" mt="auto" mb="auto" verticalAlign="center">
            <Icon as={BsFillChatSquareDotsFill} color="primary.500" />
          </Flex>
        </Flex>
      ),
      col: 1,
      row: 2,
      sub: [],
    },
    {
      label: (
        <Flex gap="4px">
          <Flex fontSize="15px" mt="auto" mb="auto" verticalAlign="center">
            <img
              src={linkedIn}
              style={{ width: '15px', height: '15px', objectFit: 'contain' }}
            />
          </Flex>
        </Flex>
      ),
      col: 1,
      row: 2,
      sub: [],
    },
  ];

export const tableHeader1: { label: any }[] = [
  { label: 'Rank' },
  { label: 'Name' },
  { label: 'Jobs Closed' },
  { label: 'Revenue' },
];

export const chartMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const byMonth = [
  {
    value: 0,
    label: 'by month',
  },
  {
    value: 1,
    label: 'by quarter',
  },
  {
    value: 2,
    label: 'current year',
  },
  {
    value: 3,
    label: 'year-to-date',
  },
];

export const byActivity = [
  {
    value: 0,
    label: 'current week',
  },
  {
    value: 1,
    label: 'current month',
  },
  {
    value: 2,
    label: 'current quarter',
  },
  {
    value: 3,
    label: 'last week',
  },
  {
    value: 4,
    label: 'last 2 weeks',
  },
  {
    value: 5,
    label: 'last month',
  },
  {
    value: 6,
    label: 'last 30 days',
  },
  {
    value: 7,
    label: 'last quarter',
  },
  {
    value: 8,
    label: 'last 3 months',
  },
  {
    value: 9,
    label: 'YTD',
  },
];
