import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import moment from 'moment-timezone';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Divider,
  Flex,
  FormControl,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  getCandidate,
  useGetPanelMembersMutation,
  useListCandidatesMutation,
  useSubmitInterviewMutation,
} from 'store/candidates.slice';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  useListCandidateInterviewMutation,
  useUpdateInterviewMutation,
} from 'store/candidateinterview.slice';
import { useGetJobsMutation, useOpenJobsMutation } from 'store/jobs.slice';
import InterviewLoading from './Loading';
import replacePlaceholders from 'utils/textPlaceholders';
import { cleanUpload, uploadList } from 'store/uploads.slice';
import InterviewDetails from './details';
import {
  listTemplate,
  usePlaceHolderPairMutation,
  useRawListTemplateConfirmationMutation,
  useRawListTemplateMutation,
} from 'store/template.slice';
import InterviewConfirmation from './confirmation';
import { setPairTemplate } from 'store/template.slice';
import InviteMessage from './invite';

import Step1 from 'assets/images/interview/step-1.svg';
import Step2 from 'assets/images/interview/step-2.svg';
import Step3 from 'assets/images/interview/step-3.svg';

import Check from 'assets/images/interview/check.svg';
import UndoneStep2 from 'assets/images/interview/undone-step-2.svg';
import UndoneStep3 from 'assets/images/interview/undone-step-3.svg';
import { useViewClientMutation } from 'store/client.slice';
import { contactInt, initialValueInt, phoneInt } from './details.interface';
import contactsPopulate from './contacts';
import { FaMobileAlt } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa6';
import replacePTags from 'utils/replaceTags';
import TemplateAddModal from 'components/app/Global/TemplateModal';
// import { useViewClientMutation } from 'store/client.slice';
interface CandidatesInterviewModalProps {
  isOpen: any;
  onClose: any;
  associate?: number;
  subject?: string;
  isJobView?: boolean;
  jobsId?: number;
  contactOptionValue?: any;
  callback?: () => void;
  isEdit: boolean;
  interviewData?: any;
}
interface CandidatePairingIntParam {
  candidate_full_name: string;
  candidate_first_name: string;
  candidate_email: string;
  candidate_current_position: string;
  candidate_current_company: string;
  candidate_mobile: string;
  candidate_linkedin_url: string;
  candidate_work_status: string;
  job_url: string;
  job_name_url: string;
  client_job_title: string;
  job_location: string;
  job_contact_name: string;
  job_contact_first_name: string;
  job_company_name: string;
  job_primary_recruiter_name: string;
  job_ad_description: string;
  job_contact_title: string;
  internal_job_description: string;
  interview_date: string;
  interview_location: string;
  interview_type: string;
  interview_mode: string;
  panel_members_details: string;
  set_interview: string;
  candidate_city: string;
  candidate_state: string;
  candidate_degree: string;
  candidate_total_years_of_exp: string;
  job_posting_title: string;
  email_signature: string;
  job_company_website: string;
  job_company_address: string;
  job_industry: string;
  job_posting_date: string;
  job_closing_date: string;
  candidate_status: string;
  candidate_source: string;
  candidate_recruiter_notes: string;
  job_requirements: string;
  application_process: string;
  hiring_manager_notes: string;
}
interface optionInterface {
  label: string;
  value: any;
}

export default function CandidatesInterviewModal({
  isOpen,
  onClose,
  associate = null,
  subject,
  isJobView = false,
  jobsId = null,
  callback = null,
  contactOptionValue = [],
  isEdit,
  interviewData,
}: CandidatesInterviewModalProps) {
  const params = useParams();
  const toast = useToast();
  const dispatch = useDispatch();

  const { candidateData, candidatePgBtn } = useSelector(
    (state: any) => state.candidates
  );
  const { group } = useSelector((state: any) => state.candidateFilters);
  const { userList } = useSelector((state: any) => state.user);
  const { openJobs, jobData } = useSelector((state: any) => state.jobs);
  const { candidateInterviewPgBtn } = useSelector(
    (state: any) => state.candidatesInterview
  );
  const { placehoderPair, aiTemplate, defaultTemplateList } = useSelector(
    (state: any) => state.template
  );

  const { selected_contact } = useSelector((state: any) => state.leads);
  const { meeting_types } = useSelector((state: any) => state.constants);
  const { uploaded } = useSelector((state: any) => state.uploads);

  const [panelOptions, setPanelOptions] = useState([]);
  const [clientPanels, setClientPanels] = useState([]);
  const [template, setTemplate] = useState(aiTemplate);

  useEffect(() => {
    setTemplate(aiTemplate);
  }, [aiTemplate]);

  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqGetJob] = useGetJobsMutation();

  const [userOptions, setUserOptions] = useState([]);

  const [reqList] = useListCandidateInterviewMutation();
  const [reqListTemp, resListTemp] = useRawListTemplateMutation();
  const [reqSubmitInterview, resSubmitInterview] = useSubmitInterviewMutation();
  const [reqUpdateInterview, resUpdateInterview] = useUpdateInterviewMutation();
  const [reqOpen, resOpen] = useOpenJobsMutation();
  const [reqGetPanel, resGetPanel] = useGetPanelMembersMutation();
  const [reqGetClient, resGetClient] = useViewClientMutation();
  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );
  const [reqPair, resPair] = usePlaceHolderPairMutation();
  const abortControllerRef = useRef<AbortController | null>(null);

  const [selectedDate, setSelectedDate] = useState(
    moment.tz('US/Pacific').format('MM-DD-YYYY')
  );

  const [minDate, setMinDate] = useState(moment.tz(moment(), 'US/Pacific'));
  const [interviewTab, setInterviewTab] = useState(0);

  const [ccOption, setCcOption] = useState([]);
  const [bccOption, setBccOption] = useState([]);

  const inviteTemplate: any =
    defaultTemplateList.filter(
      (template: any) => template?.group_id === 5
    )?.[0] ?? null;

  const confirmTemplate: any =
    defaultTemplateList.filter(
      (template: any) => template?.group_id === 4
    )?.[0] ?? null;

  useEffect(() => {
    if (resGetClient.isSuccess) {
      const company = resGetClient.data.data;
      let optionsEmails: any = [];
      if (company !== undefined) {
        if (company.leads?.length > 0) {
          company.leads.map(async (item: any) => {
            optionsEmails.push({
              label: `${[item.first_name, item.last_name]
                .filter(Boolean)
                .join(' ')} <${item.primary_email}>`,
              value: item.primary_email,
            });
          });
        }
      }

      setContactOption(optionsEmails);
      setCcOption(optionsEmails);
      setBccOption(optionsEmails);
    }
  }, [resGetClient.isSuccess, selected_contact]);

  useEffect(() => {
    if (isOpen) {
      const data = {
        candidate_id: candidateData?.id || null,
        job_id: params?.jobsId || jobsId || null,
      } as any;
      reqPair(data);

      let userOptions = [] as any;
      Promise.all(
        Object.values(userList).map((item: any) => {
          userOptions.push({
            label: [item.first_name, item.last_name].filter(Boolean).join(' '),
            value: item.email,
          });
        })
      ).then(() => {
        setUserOptions(userOptions);
      });
    }
  }, [isOpen]);

  // Query Client to get Client Managers In Case Need Client Manager
  useEffect(() => {
    if (jobData?.client?.id) {
      reqGetClient({ id: jobData?.client?.id }).then((res: any) => {});
    }
  }, [jobData]);

  // Query Panel Members (Leads and Contacts of Same Client ID)
  useEffect(() => {
    if (jobData?.client?.id) {
      reqGetPanel({ id: jobData?.client?.id }).then((result: any) => {
        let options: optionInterface[] = [];
        result?.data?.data?.map((panel: any) => {
          options.push({
            label: [panel?.first_name, panel?.last_name].join(' '),
            value: panel?.primary_email,
          });
        });
        setClientPanels(result?.data?.data);
        setPanelOptions(options);
      });
    }
  }, [jobData, selected_contact]);

  function generateTimeOptions(
    startMinutes: number,
    numOptions: number,
    minDate: any
  ) {
    return Array.from({ length: numOptions }, (_, index) => {
      let totalMinutes: number = index * 30 + startMinutes;
      const hours = Math.floor(totalMinutes / 60) % 12 || 12;
      const minutes = totalMinutes % 60;
      const period = totalMinutes < 720 ? 'AM' : 'PM';
      const formattedHour = hours.toString().padStart(2, '0');
      const formattedMinute = minutes.toString().padStart(2, '0');
      const label = `${formattedHour}:${formattedMinute} ${period}`;
      const value = totalMinutes;
      const currDate = moment(minDate).format('MM-DD-YYYY');

      const currDateInMins = minDate.hours() * 60 + minDate.minutes();
      const convertedSelectedDate = moment(selectedDate).format('MM-DD-YYYY');

      const isDisabled =
        currDate < convertedSelectedDate
          ? false
          : currDate === convertedSelectedDate
          ? totalMinutes <= currDateInMins
          : true;
      return { label, value, isDisabled };
    });
  }

  const [currentDateInMin, setCurrentDateInMin] = useState(
    minDate.hours() * 60 + minDate.minutes()
  );
  const minimumStartTime =
    480 >= currentDateInMin
      ? { label: '08:00 AM', value: 480, isDisabled: false }
      : generateTimeOptions(0, 95, minDate).find(
          (timeOption: any) => timeOption.value >= currentDateInMin
        );

  const minimumEndTime =
    480 >= currentDateInMin
      ? { label: '08:30 AM', value: 510, isDisabled: false }
      : generateTimeOptions(30, 95, minDate).find(
          (timeOption: any) => timeOption.value >= currentDateInMin + 30
        );

  let initialValues: initialValueInt | any = {
    date: moment(
      moment.tz('US/Pacific').format('MM/DD/YYYY'),
      'MM/DD/YYYY'
    ).toDate() as any,
    start_time: minimumStartTime,
    end_time: minimumEndTime,
    meeting_type: {
      label: 'Video Interview',
      value: 'Video Interview',
      id: 1,
    },
    meeting_mode: {
      label: 'Microsoft Teams',
      value: 1,
    },
    meeting_location: '',
    timezone: { label: 'US/Pacific (UTC-07:00)', value: 'US/Pacific' },
    panel_members: contactOptionValue,
    job_id: params?.jobsId || jobsId || '',
    set_interview: '0',
    contacts: null,
    phones: null,
    edit: false,
  };
  const [contactOption, setContactOption] = useState([]);
  const ccBc = (emails: any) => {
    let options: any = [];
    try {
      // console.log('email', email);
      emails.filter(Boolean).map((email: any) => {
        let label = '';
        if (contactOption?.length > 0) {
          contactOption.map(async (item: any) => {
            if (item.value == email) {
              label = item.label;
            }
          });
        }
        if (label === '') {
          Object.values(userList).map((user: any) => {
            // console.log("user.email", user.email);
            if (user.email == email) {
              label = String(
                [user.first_name, user.last_name].filter(Boolean).join(' ')
              );
            }
            if (user.email == email) {
              label = String(
                [user.first_name, user.last_name].filter(Boolean).join(' ')
              );
            }
          });
        }
        options.push({
          label: label,
          value: email,
        });
      });
    } catch (e) {
      console.warn('e', e);
    }

    return options;
  };

  const setBcc = (jobData: any) => {
    if (jobData?.bcc?.bcc) {
      return ccBc(jobData?.bcc?.bcc);
    }
    return [];
  };

  const setCc = (jobData: any) => {
    if (jobData?.bcc?.cc) {
      return ccBc(jobData?.bcc?.cc);
    }
    return [];
  };

  const checkMeetingModeId = () => {
    const filter = meeting_types.find(
      (item: any) => item.type === interviewData.meeting_type
    );
    if (filter) {
      const mode = filter.mode.find(
        (item: any) => item.mode === interviewData.meeting_mode
      );
      if (mode) {
        return mode.id;
      }
    }

    return 1;
  };

  useEffect(() => {
    const load = async () => {
      if (isEdit && interviewData) {
        const offset = moment.tz(interviewData.meeting_timezone).format('Z');
        const label = `${interviewData.meeting_timezone} (UTC${offset})`;
        const type = meeting_types.find(
          (item: any) => item.type === interviewData.meeting_type
        );

        let phones = null as any;
        let contacts = null as any;
        if (interviewData.meeting_mode === 'Client Calling Candidate') {
          const contactsOptions: contactInt[] = await contactsPopulate(
            jobData?.leads
          );
          contactsOptions.map((item: contactInt) => {
            item.phone.map((phone: phoneInt) => {
              if (phone.value === interviewData.phones) {
                phones = phone;
                contacts = item;
              }
            });
          });
        }

        if (interviewData.meeting_mode === 'Candidate Calling Client') {
          const candidateContact = candidateData?.contact;
          if (candidateContact.length > 0) {
            const find = candidateContact.find(
              (item: any) => item.number === interviewData.phones
            );

            if (find) {
              phones = {
                value: find.number,
                label: find.number,
                type: find.type,
                primary: find.primary,
                icon: find.type === 'mobile' ? FaMobileAlt : FaPhone,
              };
            }
          }
        }

        initialValues = {
          date: moment(
            moment(interviewData.meeting_date).format('MM/DD/YYYY'),
            'MM/DD/YYYY'
          ).toDate(),
          start_time: {
            label: moment(interviewData.meeting_start, 'HH:mm:ss').format(
              'hh:mm A'
            ),
            value: 480,
            isDisabled: false,
          },
          end_time: {
            label: moment(interviewData.meeting_end, 'HH:mm:ss').format(
              'hh:mm A'
            ),
            value: 510,
            isDisabled: false,
          },
          meeting_type: {
            label: interviewData.meeting_type,
            value: interviewData.meeting_type,
            id: type?.id || 1,
          },
          meeting_mode: {
            label: interviewData?.meeting_mode || 'Microsoft Teams',
            value: checkMeetingModeId(),
          },
          meeting_location: interviewData.meeting_location,
          timezone: {
            label: label,
            value: interviewData.meeting_timezone,
          },
          panel_members: contactOptionValue,
          job_id: interviewData.job_id,
          set_interview: '0',
          phones,
          contacts,
          edit: true,
        };

        setDetailInitial(initialValues);
        const newInviteInitial = {
          ...inviteInitial,
          subject: interviewData?.subject || '',
          body: interviewData?.body || '',
        };

        setInviteInitial(newInviteInitial);
        if (interviewData?.confirmation[0]) {
          const confirmation = interviewData.confirmation[0];
          let cc: any[] = [];
          let bcc: any[] = [];
          confirmation?.bcc.split(',')?.map((email: string) => {
            bcc.push({
              label: email,
              value: email,
            });
          });
          confirmation?.cc.split(',')?.map((email: string) => {
            cc.push({
              label: email,
              value: email,
            });
          });
          const initialValueConfirm = {
            ...confirmInitial,
            to: confirmation?.to || getPrimary(),
            bcc,
            cc,
            subject: confirmation?.subject || '',
            body: confirmation?.body || '',
            auto_interview: true,
          };

          setConfirmInitial(initialValueConfirm);
        } else {
          let cc = setCc(jobData);
          let bcc = setBcc(jobData);

          const initialValueConfirm = {
            ...confirmInitial,
            bcc,
            cc,
            auto_interview: false,
          };
          setConfirmInitial(initialValueConfirm);
        }
      }
    };
    load();
  }, [isEdit, interviewData]);

  useEffect(() => {
    if (jobData) {
      const initialValueConfirm = {
        ...confirmInitial,
        bcc: setBcc(jobData),
        cc: setCc(jobData),
      };
      setConfirmInitial(initialValueConfirm);
    }
  }, [jobData, contactOption.length, userList.length]);

  const [detailInitial, setDetailInitial] =
    useState<initialValueInt>(initialValues);

  const getPrimary = () => {
    let email = '';
    try {
      const firstemail =
        candidateData?.emails?.length > 0 ? candidateData?.emails[0] : null;
      const data =
        candidateData?.emails?.find((item: any) => item.primary === 1) ||
        firstemail;
      email = data?.email || '';
    } catch (e) {}
    return email;
  };

  const populateTemplate = (template: any) => {
    try {
      if (template) {
        return {
          value: template?.id,
          label: template?.title,
          subject: template?.subject,
          body: template?.body,
          attachments: template?.attachments,
        };
      } else {
        return null as any;
      }
    } catch (e) {
      return null;
    }
  };

  const initialValueInvite = {
    template: {},
    subject: '',
    body: '',
    primary: true,
    attachment: uploaded,
  };

  const initialValueConfirm = {
    to: getPrimary(),
    bcc: [] as any,
    cc: [] as any,
    subject: '',
    body: '',
    auto_interview: true,
    template: {},
    attachment: uploaded,
  };

  const [inviteInitial, setInviteInitial] = useState(initialValueInvite);
  const [confirmInitial, setConfirmInitial] = useState(initialValueConfirm);
  const [submitDetails, setSubmitDetails] = useState({} as any);

  const handleResetTemplate = () => {
    setInviteInitial(initialValueInvite);
    setConfirmInitial(initialValueConfirm);
  };

  const convertToMilitaryTime = (time12Hour: any) => {
    const [time, period] = time12Hour.split(' ');

    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  };

  const getPrimaryMobile = (contact: any) => {
    try {
      let mobile = '';

      contact.map((item: any) => {
        if (item.primary && item.type == 'mobile') {
          mobile = item?.number;
        }
      });

      return mobile;
    } catch (e) {
      return '';
    }
  };

  const [schedInterview, setSchedInterview] = useState(
    detailInitial.set_interview
  );

  useEffect(() => {
    if (isOpen) {
      if (candidateData) {
        const candidate_full_name = candidateData?.data
          ? [candidateData?.data?.first_name, candidateData?.data?.last_name]
              .filter(Boolean)
              .join(', ')
          : [candidateData?.first_name, candidateData?.last_name]
              .filter(Boolean)
              .join(', ') || '';
        const candidate_first_name =
          candidateData?.data?.first_name ||
          candidateData?.first_name ||
          placehoderPair?.first_name;
        const candidate_email =
          candidateData?.data?.primary_email ||
          candidateData?.primary_email ||
          placehoderPair?.candidate_email;
        const candidate_current_position =
          candidateData?.data?.latest_job_title ||
          candidateData?.latest_job_title ||
          '';
        const candidate_current_company =
          candidateData?.data?.latest_employer ||
          candidateData?.latest_employer ||
          '';
        const candidate_mobile =
          getPrimaryMobile(candidateData?.data?.contact) ||
          placehoderPair?.candidate_mobile;
        const candidate_linkedin_url = candidateData?.linkedin_url;
        const candidate_work_status = candidateData?.work_status?.status || '';
        let job_url = '';
        let job_name_url = '';
        let job_title = '';
        let job_location = '';
        let job_contact_name = '';
        let job_contact_first_name = '';
        let job_company_name = '';
        let job_primary_recruiter_name = '';
        let job_ad_description = '';
        let job_contact_title = '';
        let internal_job_description = '';
        let interview_date = '';
        let interview_location = '';
        let interview_type = '';
        let interview_mode = '';
        let panel_members_details = '';
        let set_interview = '';
        let candidate_city = '';
        let candidate_state = '';
        let candidate_degree = '';
        let candidate_total_years_of_exp = '';
        let job_posting_title = '';
        let email_signature = '';
        let job_company_website = '';
        let job_company_address = '';
        let job_industry = '';
        let job_posting_date = '';
        let job_closing_date = '';
        let candidate_status = '';
        let candidate_source = '';
        let candidate_recruiter_notes = '';
        let job_requirements = '';
        let application_process = '';
        let hiring_manager_notes = '';
        if (params?.jobsId || jobsId) {
          job_title = jobData?.title || '';
          job_location =
            [
              jobData?.data?.location?.city,
              jobData?.data?.location?.state,
              jobData?.data?.location?.country?.country,
            ]
              .filter(Boolean)
              .join(', ') || '';
          job_contact_name =
            [jobData?.lead?.first_name, jobData?.lead?.last_name]
              .filter(Boolean)
              .join(', ') || '';
          job_contact_first_name = jobData?.lead?.first_name || '';
          job_company_name = jobData?.client?.name || '';
          job_primary_recruiter_name =
            [
              jobData?.primary?.recruiter?.first_name,
              jobData?.primary?.recruiter?.last_name,
            ]
              .filter(Boolean)
              .join(', ') || '';
          job_contact_title =
            jobData?.lead?.title || placehoderPair?.job_contact_title || '';
          internal_job_description =
            jobData?.data?.description ||
            placehoderPair?.internal_job_description ||
            '';
          if (jobData?.jobAds?.length > 0) {
            const ads = jobData?.jobAds?.[0];
            job_ad_description =
              ads?.description || placehoderPair?.job_ad_description;
            if (ads) {
              const url = `https://careers.talently.com/job-details/${ads?.id}`;
              job_url = `<a href="${url}" target="_blank">${url}</a>`;
              job_name_url = `<a href="${url}" target="_blank">${ads.title}</a>`;
            }
          }
        }

        const candidateParam = {
          candidate_full_name,
          candidate_first_name,
          candidate_email,
          candidate_current_position,
          candidate_current_company,
          candidate_mobile,
          candidate_linkedin_url,
          candidate_work_status,
          job_url,
          job_name_url,
          client_job_title: job_title,
          job_location,
          job_contact_name,
          job_contact_first_name,
          job_company_name,
          job_primary_recruiter_name,
          job_ad_description,
          job_contact_title,
          internal_job_description,
          interview_date,
          interview_location,
          interview_type,
          interview_mode,
          panel_members_details,
          set_interview,
          candidate_city,
          candidate_state,
          candidate_degree,
          candidate_total_years_of_exp,
          job_posting_title,
          email_signature,
          job_company_website,
          job_company_address,
          job_industry,
          job_posting_date,
          job_closing_date,
          candidate_status,
          candidate_source,
          candidate_recruiter_notes,
          job_requirements,
          application_process,
          hiring_manager_notes,
        } as CandidatePairingIntParam;

        candidatePairing(candidateParam);
      }
    }
  }, [isOpen]);

  const candidatePairing = async (param: CandidatePairingIntParam) => {
    const newpair = {
      ...placehoderPair,
      candidate_full_name: param.candidate_full_name,
      candidate_first_name: param.candidate_first_name,
      candidate_email: param.candidate_email,
      candidate_current_position: param.candidate_current_position,
      candidate_current_company: param.candidate_current_company,
      candidate_mobile: param.candidate_mobile,
      candidate_linkedin_url: param.candidate_linkedin_url,
      candidate_work_status: param.candidate_work_status,
      candidate_city: param.candidate_city,
      candidate_state: param.candidate_state,
      candidate_degree: param.candidate_degree,
      candidate_total_years_of_exp: param.candidate_total_years_of_exp,
      job_url: param.job_url,
      job_name_url: param.job_name_url,
      client_job_title: param.client_job_title,
      job_location: param.job_location,
      internal_job_description: param.internal_job_description,
      job_contact_name: param.job_contact_name,
      job_contact_first_name: param.job_contact_first_name,
      job_contact_title: param.job_contact_title,
      job_company_name: param.job_company_name,
      job_primary_recruiter_name: param.job_primary_recruiter_name,
      job_ad_description: param.job_ad_description,
      job_posting_title: param.job_posting_title,
      email_signature: param.email_signature,
      job_company_website: param.job_company_website,
      job_company_address: param.job_company_address,
      job_industry: param.job_industry,
      job_posting_date: param.job_posting_date,
      job_closing_date: param.job_closing_date,
      candidate_status: param.candidate_status,
      candidate_source: param.candidate_source,
      candidate_recruiter_notes: param.candidate_recruiter_notes,
      job_requirements: param.job_requirements,
      application_process: param.application_process,
      hiring_manager_notes: param.hiring_manager_notes,
      interview_type: param.interview_type,
      interview_location: param.interview_location,
      interview_date: param.interview_date,
      interview_mode: param.interview_mode,
      panel_members_details: param.panel_members_details,
    };

    dispatch(setPairTemplate({ placehoderPair: newpair }));
  };

  const placeholders = (text: string) => {
    //  replacePlaceholders(text, placehoderPair);
    let data = replacePlaceholders(text, placehoderPair);
    if (
      submitDetails.meeting_type === 'Video Interview' &&
      submitDetails.meeting_mode.label === 'Microsoft Teams' &&
      submitDetails.meeting_location === ''
    ) {
      data = data.replace(
        /\{\{interview_location\}\}/i,
        'https://teams.microsoft.com/l/meetup-join/'
      );
    }
    return data;
  };

  const finalPlaceholders = (text: string) => {
    // Revert interview location which have "Microsoft Teams" type back to {{interview_location}}
    // as this will be changed into the actual teams url in the api
    // if (placehoderPair?.interview_type === 'Microsoft Teams') {
    //   const newPlaceholderPair = {
    //     ...placehoderPair,
    //   };
    //   delete newPlaceholderPair.interview_location;
    //   console.log("newPlaceholderPair", newPlaceholderPair)
    //   return replacePlaceholders(text, newPlaceholderPair);
    // }
    return replacePlaceholders(text, placehoderPair);
  };

  const onSubmitNext = (data: any) => {
    const panelMembers =
      data.panel_members.length > 0
        ? data.panel_members.map((member: any) => member.value)
        : [];
    const newParam = {
      meeting_type: data.meeting_type.value,
      meeting_mode: data.meeting_mode,
      meeting_date: data.date,
      meeting_start: convertToMilitaryTime(data.start_time.label),
      meeting_end: convertToMilitaryTime(data.end_time.label),
      meeting_location: data.meeting_location,
      meeting_timezone: data.timezone.value,
      panel_members: panelMembers,
      associate: associate,
      job_id: data.job_id,
      set_interview: data.set_interview,
      mode_id: data.meeting_mode.value,
      type_id: data.meeting_type.id,
      phones: data.phones?.value || null,
    };

    setSubmitDetails(newParam);
    setDetailInitial(data);
    setInterviewTab(1);
  };

  const onPevClick = (data: any) => {
    if (interviewTab === 1) {
      setInviteInitial(data);
    } else if (interviewTab === 2) {
      setConfirmInitial(data);
    }
    setInterviewTab((prev) => prev - 1);
  };

  useEffect(() => {
    if (schedInterview === '1') {
      setDetailInitial((detail: any) => ({
        ...detail,
        panel_members: [],
      }));
    }
  }, [schedInterview]);

  const onSubmitInvite = (data: any) => {
    const invitation = {
      body: finalPlaceholders(replacePTags(data.body)),
      subject: finalPlaceholders(data.subject),
    };
    const formData = {
      ...submitDetails,
      inviteData: invitation,
      primary: data.primary,
      attachments: data.attachment,
    };
    setSubmitDetails(formData);
    setInviteInitial(data);

    schedInterview === '1'
      ? reqSubmitInterview({ data: formData, id: candidateData.id })
      : setInterviewTab(2);
  };

  const onSubmit = (data: any) => {
    const bcc =
      data.bcc.length > 0 ? data.bcc.map((member: any) => member.value) : [];
    const cc =
      data.cc.length > 0 ? data.cc.map((member: any) => member.value) : [];

    const confirmation = {
      attachment: data.attachment,
      auto_interview: data.auto_interview,
      body: finalPlaceholders(replacePTags(data.body)),
      bcc: bcc,
      cc: cc,
      subject: finalPlaceholders(data.subject),
      to: data.to,
    };

    const formData = {
      ...submitDetails,
      meeting_date: moment(submitDetails.meeting_date).format('MM-DD-YYYY'),
      confirmData: confirmation,
    };

    // if (formData.meeting_type !== 'Video Interview') {
    //   delete formData.meeting_mode;
    // }

    if (isEdit) {
      const { job_id, associate, ...rest } = formData;

      reqUpdateInterview({ data: rest, id: interviewData?.id });
    } else {
      reqSubmitInterview({ data: formData, id: candidateData.id });
    }
  };
  // Query Panel Members (Leads and Contacts of Same Client ID)

  useEffect(() => {
    const reload = async () => {
      const title = 'Submit Interview';
      const description = 'Interview successfully submitted';
      toast({
        title: title,
        description: description,
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top',
      });
      await dispatch(cleanUpload({ attachments: [] }));
      await dispatch(cleanUpload({ uploaded: [] }));
      onClose();
      dispatch(listTemplate({ templateData: {} }));
      if (candidateData?.id && !group) {
        reqList({
          data: candidateInterviewPgBtn,
          id: candidateData.id,
        });
      }
      if (isJobView) {
        setTimeout(async () => {
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }

          abortControllerRef.current = new AbortController();
          await reqCandidates({
            data: candidatePgBtn,
            signal: abortControllerRef.current.signal,
          });
        }, 1000);

        const job_id = params?.jobsId || jobsId || jobData.id;

        await reqGetJob({ id: job_id });
      }
      if (callback) {
        callback();
      }
    };
    if (resSubmitInterview.isSuccess || resUpdateInterview.isSuccess) {
      setTimeout(reload, 100);
      dispatch(
        uploadList({
          uploaded: [],
          attachments: [],
          attachmentInvite: [],
          attachmentConfirmation: [],
        })
      );
    }
  }, [resSubmitInterview.isSuccess, resUpdateInterview.isSuccess]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (resSubmitInterview.isError || resUpdateInterview.isError) {
      openAlert();
      if (candidateData.candidatesId) {
        reqList({
          data: candidateInterviewPgBtn,
          id: candidateData.candidatesId,
        });
      }

      const error: any = resSubmitInterview.error || resUpdateInterview.error;
      try {
        setErrorMsg(error.data.error.message);
      } catch (e) {
        setErrorMsg('Unknown error occured.');
      }
    }
  }, [resSubmitInterview.isError, resUpdateInterview.isError]);

  const [tabTitle, setTabTitle] = useState([]);

  const handleSwitchInterview = (
    setFieldValue: any,
    fieldName: string,
    value: string
  ) => {
    if (value === '0') {
      setFieldValue(fieldName, contactOptionValue);
    } else if (value === '1') {
      setFieldValue(fieldName, []);
    }
  };

  useEffect(() => {
    if (interviewTab === 0) {
      setTabTitle([
        {
          title: (
            <Flex gap="10px">
              <Image src={Step1} />
              <Box color="#6930CA">Meeting Details</Box>
            </Flex>
          ),
        },
        {
          title: (
            <Flex gap="10px">
              <Image src={UndoneStep2} />
              <Box color="#9B9B9B">Invite Message</Box>
            </Flex>
          ),
        },
        ...(schedInterview === '0'
          ? [
              {
                title: (
                  <Flex gap="10px">
                    <Image src={UndoneStep3} />
                    <Box color="#9B9B9B">Confirmation Email</Box>
                  </Flex>
                ),
              },
            ]
          : []),
      ]);
    } else if (interviewTab === 1) {
      setTabTitle([
        {
          title: (
            <Flex gap="10px">
              <Image src={Check} />
              <Box color="#6930CA">Meeting Details</Box>
            </Flex>
          ),
        },
        {
          title: (
            <Flex gap="10px">
              <Image src={Step2} />
              <Box color="#6930CA">Invite Message</Box>
            </Flex>
          ),
        },
        ...(schedInterview === '0'
          ? [
              {
                title: (
                  <Flex gap="10px">
                    <Image src={UndoneStep3} />
                    <Box color="#9B9B9B">Confirmation Email</Box>
                  </Flex>
                ),
              },
            ]
          : []),
      ]);
    } else {
      setTabTitle([
        {
          title: (
            <Flex gap="10px">
              <Image src={Check} />
              <Box color="#6930CA">Meeting Details</Box>
            </Flex>
          ),
        },
        {
          title: (
            <Flex gap="10px">
              <Image src={Check} />
              <Box color="#6930CA">Invite Message</Box>
            </Flex>
          ),
        },
        ...(schedInterview === '0'
          ? [
              {
                title: (
                  <Flex gap="10px">
                    <Image src={Step3} />
                    <Box color="#6930CA">Confirmation Email</Box>
                  </Flex>
                ),
              },
            ]
          : []),
      ]);
    }
  }, [interviewTab, schedInterview]);

  const {
    isOpen: addOpen,
    onOpen: addOnOpen,
    onClose: addOnClose,
  } = useDisclosure();

  const [aiTemplateValue, setAiTemplateValue] = useState(
    interviewTab === 1
      ? populateTemplate(
          aiTemplate.filter(
            (val: any) => val.value === (inviteInitial as any)?.value
          )
        ) || aiTemplate[0]
      : populateTemplate(
          aiTemplate.filter(
            (val: any) => val.value === (confirmInitial as any)?.value
          )
        ) || aiTemplate[0]
  );

  const [isCallback, setIsCallback] = useState(false);

  useEffect(() => {
    if (!isCallback) {
      const value =
        interviewTab === 1
          ? populateTemplate(
              aiTemplate.filter(
                (val: any) => val.value === (inviteInitial as any)?.value
              )
            )
          : populateTemplate(
              aiTemplate.filter(
                (val: any) => val.value === (confirmInitial as any)?.value
              )
            );

      if (value.length > 0) {
        setAiTemplateValue(value);
      } else {
        setAiTemplateValue(aiTemplate[0]);
      }
    }
  }, [isCallback, aiTemplate, inviteTemplate, confirmTemplate, interviewTab]);

  useEffect(() => {
    setIsCallback(false);
  }, [interviewTab]);

  useEffect(() => {
    dispatch(listTemplate({ aiTemplate: [] }));
  }, [interviewTab]);

  const templateCallback = () => {
    const id = interviewTab === 1 ? 5 : 4;

    setIsCallback(true);
    reqListTemp({ id: id }).then((res: any) => {
      const data = res.data.data;

      const highestIdIndex = data.reduce(
        (maxIndex: any, item: any, index: any, array: any) =>
          item.id > array[maxIndex].id ? index : maxIndex,
        0
      );

      const temp = data[highestIdIndex];

      setAiTemplateValue(populateTemplate(temp));
      interviewTab === 1 &&
        setInviteInitial((prevState) => ({
          ...prevState,
          ...populateTemplate(temp),
        }));
      interviewTab === 2 &&
        setConfirmInitial((prevState) => ({
          ...prevState,
          ...populateTemplate(temp),
        }));
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        contactOptionValue = [];
        dispatch(getCandidate({ panelMembers: {} }));
        dispatch(listTemplate({ aiTemplate: [] }));
        dispatch(cleanUpload({ attachments: [] }));
        dispatch(cleanUpload({ uploaded: [] }));
        onClose();
      }}
      size="6xl"
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        fontFamily="NunitoSans Regular"
        maxW={{
          '1280px': '1200px', // 1280 x 832
          '1366px': '1200px', // 1366 x 768
          '1400px': '1300px', // 1400 x 900
          '1512px': '1400px', // 1512 x 982
          '1728px': '1500px', // 1728 x 1117
          '1920px': '1500px', // 1920 x 1280
        }}
        w={{
          '1280px': '1200px', // 1280 x 832
          '1366px': '1200px', // 1366 x 768
          '1400px': '1300px', // 1400 x 900
          '1512px': '1400px', // 1512 x 982
          '1728px': '1500px', // 1728 x 1117
          '1920px': '1500px', // 1920 x 1280
        }}
        maxH={{
          '1280px': '550px', // 1280 x 832
          '1366px': '550px', // 1366 x 768
          '1400px': '700px', // 1400 x 900
          '1512px': '700px', // 1512 x 982
          '1728px': '800px', // 1728 x 1117
          '1920px': '800px', // 1920 x 1280
        }}
        h={{
          '1280px': '550px', // 1280 x 832
          '1366px': '550px', // 1366 x 768
          '1400px': '700px', // 1400 x 900
          '1512px': '700px', // 1512 x 982
          '1728px': '800px', // 1728 x 1117
          '1920px': '800px', // 1920 x 1280
        }}
      >
        <ModalHeader
          sx={{
            background: '#F9FAFB',
            borderRadius: '4px 4px 0',
            p: '8px 16px',
          }}
        >
          <Flex justifyContent="space-between" alignItems="center" h="40px">
            <Text
              sx={{
                fontSize: '18px',
                lineHeight: '18px',
                fontWeight: 700,
                color: '#2B2D42',
                textAlign: 'left',
              }}
            >
              Schedule Interview
            </Text>
            {interviewTab !== 0 && (
              <FormControl sx={{ zIndex: 2, width: '169px' }}>
                <Select
                  placeholder="Select a Template"
                  isMulti={false}
                  value={aiTemplateValue}
                  options={template}
                  onChange={(e: any) => {
                    setAiTemplateValue(e);

                    console.log(e, 'xxx te eee');

                    interviewTab === 1 &&
                      setInviteInitial((prevState) => ({ ...prevState, ...e }));
                    interviewTab === 2 &&
                      setConfirmInitial((prevState) => ({
                        ...prevState,
                        ...e,
                      }));
                  }}
                  styles={{
                    control: (styles: any) => ({
                      ...styles,
                      height: '40px',
                      width: '169px',
                      fontSize: '14px',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      boxShadow: '0px 2px 8px 0px #0000000A',
                      zIndex: 2,
                    }),
                    dropdownIndicator: (styles: any) => ({
                      ...styles,
                      color: '#718096',
                    }),
                    indicatorSeparator: () => ({
                      display: 'none',
                    }),
                    placeholder: (styles: any) => ({
                      ...styles,
                      fontSize: '14px',
                      color: '#2B2D42',
                    }),
                    multiValue: (styles: any) => ({
                      ...styles,
                      background: '#F7FAFC',
                      border: '1px solid #E2E8F0',
                      borderRadius: '6px',
                      '&& div:first-of-type': {
                        color: '#6930CA',
                        fontWeight: 600,
                      },
                    }),
                  }}
                  components={{
                    Option: ({ label, data, innerProps }) => {
                      const isCreate = label === 'Create New Template';
                      return (
                        <div {...innerProps}>
                          {isCreate && <Divider m={0} />}
                          <Text
                            sx={{
                              p: '8px 16px',
                              fontSize: '12px',
                              color: '#2B2D42',
                              cursor: 'pointer',
                              fontWeight: isCreate ? 700 : 400,
                            }}
                            onClick={(e) => {
                              isCreate ? addOnOpen() : innerProps.onClick(e);
                            }}
                          >
                            {label}
                          </Text>
                        </div>
                      );
                    },
                  }}
                />
              </FormControl>
            )}
          </Flex>
        </ModalHeader>

        {isOpenAlert && (
          <Box>
            <Alert status="error" justifyContent="space-around">
              <AlertIcon />
              <Box width="100%">
                <AlertTitle>Oh snap!</AlertTitle>
                <AlertDescription>{errMsg}</AlertDescription>
              </Box>
              <CloseButton
                position="relative"
                right={-1}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}

        <ModalBody
          sx={{
            bgColor: '#F9FAFB',
            borderRadius: '0 0 4px 4px',
            p: 0,
            h: '100%',
          }}
        >
          {resOpen.isLoading ? (
            <InterviewLoading />
          ) : (
            <Box position="relative" h="calc(100% - 101px)">
              <Tabs colorScheme="purple" index={interviewTab} h="100%">
                <TabList
                  sx={{
                    p: '0 16px',
                    gap: '10px',
                    justifyContent: 'center',
                    fontSize: '14px',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    border: 'none',
                    background: '#F9FAFB',
                  }}
                >
                  <Tab
                    sx={{
                      width: '100%',
                      borderBottom: '4px solid #EBEBEB',
                      fontWeight: 700,
                      cursor: 'auto',
                      '&:focus': {
                        boxShadow: 'none',
                        background: 'none',
                      },
                    }}
                  >
                    {tabTitle[0]?.title}
                  </Tab>
                  <Tab
                    sx={{
                      width: '100%',
                      borderBottom: '4px solid #EBEBEB',
                      fontWeight: 700,
                      cursor: 'auto',
                      '&:focus': {
                        boxShadow: 'none',
                        background: 'none',
                      },
                    }}
                  >
                    {tabTitle[1]?.title}
                  </Tab>
                  {tabTitle[2]?.title && (
                    <Tab
                      sx={{
                        width: '100%',
                        borderBottom: '4px solid #EBEBEB',
                        fontWeight: 700,
                        cursor: 'auto',
                        '&:focus': {
                          boxShadow: 'none',
                          background: 'none',
                        },
                      }}
                    >
                      {tabTitle[2]?.title}
                    </Tab>
                  )}
                  <Box
                    sx={{
                      p: '10px',
                      width: '250px',
                      fontSize: '14px',
                      textAlign: 'right',
                      fontWeight: 700,
                      color: '#718096',
                    }}
                  >
                    Step {interviewTab + 1} / {tabTitle.length}
                  </Box>
                </TabList>
                <TabIndicator color="#6930CA" />
                <TabPanels h="100%">
                  <TabPanel p={0} h="100%">
                    {interviewTab === 0 && (
                      <InterviewDetails
                        onSubmit={(e: any) => {
                          onSubmitNext(e);
                        }}
                        initialValues={detailInitial}
                        jobData={jobData}
                        candidateData={candidateData}
                        jobsId={params?.jobsId || jobsId || false}
                        openJobs={openJobs}
                        placeholders={(e: any) => placeholders(e)}
                        panelOptions={panelOptions}
                        candidatePairing={candidatePairing}
                        userOptions={userOptions}
                        schedInterview={schedInterview}
                        setSchedInterview={setSchedInterview}
                        handleSwitchInterview={handleSwitchInterview}
                        clientPanels={clientPanels}
                        convertToMilitaryTime={convertToMilitaryTime}
                        onClose={onClose}
                        handleResetTemplate={handleResetTemplate}
                      />
                    )}
                  </TabPanel>
                  <TabPanel p={0} h="100%">
                    {interviewTab === 1 && (
                      <InviteMessage
                        candidateData={candidateData}
                        initialvalues={inviteInitial}
                        onSubmit={(e: any) => {
                          onSubmitInvite(e);
                        }}
                        onPrev={(e: any) => onPevClick(e)}
                        jobData={jobData}
                        placeholders={(e: any) => placeholders(e)}
                        loading={resSubmitInterview.isLoading}
                        userOptions={userOptions}
                        schedInterview={schedInterview}
                        candidatePairing={candidatePairing}
                        submitDetails={submitDetails}
                        onClose={onClose}
                        panelOptions={panelOptions}
                      />
                    )}
                  </TabPanel>
                  <TabPanel p={0} h="100%">
                    {interviewTab === 2 && (
                      <InterviewConfirmation
                        initialvalues={confirmInitial}
                        onSubmit={(e: any) => onSubmit(e)}
                        onPrev={(e: any) => onPevClick(e)}
                        jobData={jobData}
                        // placeholders={(e: any) => placeholders(e)}
                        panelOptions={panelOptions}
                        loading={
                          resSubmitInterview.isLoading ||
                          resUpdateInterview.isLoading
                        }
                        userOptions={userOptions}
                        candidatePairing={candidatePairing}
                        submitDetails={submitDetails}
                        ccOptions={ccOption}
                        bccOptions={bccOption}
                        setCcOptions={setCcOption}
                        setBccOptions={setBccOption}
                        contactOptionsEmail={contactOption}
                        onClose={onClose}
                      />
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          )}
          {addOpen && (
            <TemplateAddModal
              isOpen={addOpen}
              onClose={addOnClose}
              templateCallback={templateCallback}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
