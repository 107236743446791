import { Box, Td, Tr, Link } from '@chakra-ui/react';
import moment from 'moment';
import { formatDigits } from 'utils/utilFormat';

interface BreakDownInt {
  data: any;
  index: number;
  dayBreakclick: (type: string, date: string) => void;
}

const BreakDown = ({ data, index, dayBreakclick }: BreakDownInt) => {
  const clickBreakDown = (event: any, type: string) => {
    event.stopPropagation();
    dayBreakclick(type, moment(data.day_start_date).format('M/DD/YYYY'));
  };
  return (
    <>
      <Tr
        cursor="pointer"
        key={`activity-logs-breakdown-${data.label}`}
        sx={{
          fontSize: '16px',
          lineHeight: '16px',
        }}
      >
        <Td
          sx={{
            bg: '#DEE2E6 !important',
            border: '1px solid #bababa !important',
            borderTopColor: '#e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
        >
          {moment(data.day_start_date).format('M/DD/YYYY')} - {data.day}
        </Td>
        <Td
          style={{
            border: '1px solid #e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {Number(data.new_leads_talently) !== 0
            ? <Link onClick={(e) => clickBreakDown(e, 'new leads talently')}>
            {data.new_leads_talently}
          </Link>
            : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #e1e1e1',
            borderRightColor: '#bababa',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {Number(data.new_leads_repeat) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'new leads repeat')}>
              {data.new_leads_repeat}
            </Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {Number(data.new_client_interview) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'new client w/ interview')}>
              {data.new_client_interview}
            </Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{
            border: '1px solid #e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {' '}
          {Number(data.job_posted) !== 0 ? data.job_posted : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #e1e1e1',
            borderRightColor: '#bababa',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {Number(data.job_total) !== 0 ? data.job_total : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {Number(data.submits) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'submits')}>
              {data.submits}
            </Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderRightColor: '#e1e1e1',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {Number(data.first_interview) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'first interview')}>
              {data.first_interview}
            </Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderLeftColor: '#e1e1e1',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {' '}
          {Number(data.interviews) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'interviews')}>
              {data.interviews}
            </Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {Number(data.offers) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'offers')}>
              {data.offers}
            </Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {Number(data.placements) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'placements')}>
              {data.placements}
            </Link>
          ) : (
            '-'
          )}
        </Td>

        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {Number(data.revenue) !== 0 ? formatDigits(data.revenue) : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {Number(data.calls) !== 0 ? data.calls : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {Number(data.emails) !== 0 ? data.emails : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {' '}
          {Number(data.sms) !== 0 ? data.sms : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
          textAlign="center"
        >
          {Number(data.inmail) !== 0 ? data.inmail : '-'}
        </Td>
      </Tr>
    </>
  );
};
export default BreakDown;
